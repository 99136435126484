import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
export default function NewFooter() {
  return (
    <footer id="footer">
      {/* <div className="footer-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4>Our Newsletter</h4>
              <p>
                Get updated news, company's new product release, new technology
                release with us.
              </p>
            </div>
            <div className="col-lg-6">
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div> */}

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-links ">
              <h4 className="text-white">Useful Links</h4>
              <ul>
                <li>
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  {/* <i className="bx bx-chevron-right"></i>  */}
                  <a href="/">Home</a>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/about/eca-at-a-glance">ECA at a glance</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/b-to-env">ECA Benefit to Environment</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/socio-cultural">Socio Cultural Activities</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/social-activity">Social Acitivities</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/conf">Confidentiality</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4 className="text-white">Our Services</h4>
              <ul>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-sm  fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/services">Services Overview</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="services/audit-assurance">Audit and Assurance</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/services/advisory">Advisory</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/services/taxation">Taxation</Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/services/capacity-development">
                    Capacity Development
                  </Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/services/legal-services">
                    Legal & Corporate Services
                  </Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right"></i>{" "} */}
                  <i
                    class="fa fa-chevron-right fa-xs text-white mr-2"
                    aria-hidden="true"
                  ></i>
                  <Link to="/services/research-survey">Research & Survey</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-contact">
              <Link to="/contact">
                <h4 className="text-white">Contact Us</h4>
              </Link>
              <p className="text-white">
                OFFICE #6, 4TH FLOOR <br />
                Muslim Business Center
                <br />
                Haji Yaqoob Square, Shar-e-naw
                <br />
                Kabul, Afghanistan <br />
                <br />
                <strong>Phone</strong>
                <br></br> +93 (0) 20 22 02 179 <br></br> +93 (0) 798 87 17 45
                <br />
                <strong>Email</strong>
                <br></br> info@eca.org.af
                <br />
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-info">
              <h3 className="text-white"> About ECA</h3>
              <p className="text-white">
                ECA is Afghanistan based company formally registered in 2014
                with Ministry of Commerce, Afghanistan under license number
                D-66649. The company is privately owned and governed by a group
                of Afghans, having extensive experience, professional skills and
                qualifications.
              </p>

              <div className="social-links mt-3">
                <a href="#" className="twitter">
                  {/* <i class="fa fa-twitter" aria-hidden="true"></i> */}
                  {/* <i className="bx bxl-twitter"></i> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-twitter"
                  >
                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/EAGLE-WISE-102567754817577"
                  className="facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <i className="bx bxl-facebook"></i> */}
                  {/* <i class="fa fa-facebook" aria-hidden="true"></i> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-facebook"
                  >
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                  </svg>
                </a>
                <a href="#" className="instagram">
                  {/* <i className="bx bxl-instagram"></i> */}
                  {/* <i class="fa fa-instagram" aria-hidden="true"></i> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-instagram"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                  </svg>
                </a>

                <a
                  href="https://www.linkedin.com/company/eagle-wise"
                  className="linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-linkedin"
                  >
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                  {/* <i class="fa fa-linkedin" aria-hidden="true"></i> */}
                  {/* <i className="bx bxl-linkedin"></i> */}
                </a>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      <div class="container py-4">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>ECA</span>
          </strong>
          . All Rights Reserved
        </div>
        <div class="credits">
          {/* Designed by{" "} */}
          <a href="/">Eagle Wise Chartered Accountants</a>
        </div>
      </div>
    </footer>
  );
}
