import React from "react";
import aci from "../../assets/clients/aci.png";
// import pax from "../../assets/clients/pax.jpg";
// import eve from "../../assets/clients/eve.png";
// import mashal from "../../assets/clients/mashal.jpg";
// import brac from "../../assets/clients/brac.png";
import aah from "../../assets/clients/aah.png";
// import sabatv from "../../assets/clients/sabatv.jpg";
import gs from "../../assets/clients/gs.jpeg";
import imc2 from "../../assets/clients/imc2.jpg";
// import agt from "../../assets/clients/agt.jpg";
import mc from "../../assets/clients/mc.jpeg";
import usaid from "../../assets/clients/usaid.png";
import scg from "../../assets/clients/scg.png";
import care from "../../assets/clients/care.png";
import undp from "../../assets/clients/undp.svg";
import aib from "../../assets/clients/aib.png";
import pashtany from "../../assets/clients/pashtany.png";
import nz from "../../assets/clients/nz.png";
export default function ClientPage() {
  return (
    <section id="clients" class="section-bg">
      <div class="container">
        <div class="section-header">
          <h3>Key Clients</h3>
          <p></p>
        </div>
        <div
          class="row no-gutters clients-wrap clearfix wow fadeInUp"
          //   style="visibility: visible; animation-name: fadeInUp;"
        >
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img
                src={undp}
                class="img-fluid"
                alt=""
                style={{ width: 70, height: 120 }}
              />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={care} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={aci} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={imc2} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={gs} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={aah} class="img-fluid" alt="" />{" "}
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={mc} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={usaid} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={scg} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={aib} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={pashtany} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={nz} class="img-fluid" alt="" />{" "}
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>Ministry of Rehabilitaion & Development</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
