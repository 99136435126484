export const Audit = [
  {
    id: 2,
    name: "Hayatullah Sahak",
    board: "Manager – Audit & Assurance",
    title: "ACCA Member",
    details:
      "Mr. Hayatullah has worked in different organizations such as Crowe Horwath Afghanistan, American University of Afghanistan and Ministry of finance. He has great experience in External Audit, Internal Audit and Financial Management. He has completed many external audit and internal audit assignments in different public sector entities, national and international organizations.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/hayat.jpg",
  },
  {
    id: 11,
    name: "Zia Ur Rahman",
    board: "Sr. Supervisor – Audit & Assurance ",
    title: "CISA, ACCA (Finalist) & BA Economics",
    details:
      "Mr. Zia has worked in Ernst & Young (EY), Grant Thornton Afghanistan, Ministry of Finance and Da Afghanistan Breshna Shirkat (DABS). He has great experience of External Auditing, Internal Auditing and Risk Advisory Services. He has completed many external audits, micro assessments, risk assessments, compliance audits, system audits and financial management in banking sector, power sector, public sector and NGO sector. ",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/male.png",
  },

  {
    id: 18,
    name: "Muneer Muhammadi",
    board: "Supervisor – Audit & Assurance ",
    title: "ACCA (Finalist)",
    details:
      "Mr. Muneer has worked with well-known organizations, such as Supreme Audit Office of Afghanistan (SAO), French Medical Institute for Mothers and Children (FMIC), Deloitte Yousuf Audil, Chartered Accountants, Ernst & Young Ford Rhodes Sidat Hyder and Crowe Horwath Afghanistan. Mr. Muneer has completed multiple external audit assignments in both public, and private sectors including telecommunications, micro-finance and banking, airline and aviation, NGOs, power and health industries.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/muneer.jpg",
  },

  {
    id: 19,
    name: "Rahmat Karimi",
    board: "Supervisor – Audit & Assurance ",
    title: "ACCA (Finalist)",
    details:
      "Mr. Rahmat Karimi has worked in different organization including PKF F.R.A.N.T.S Chartered Accountants and Kabul Bank Receivership (KBR). He has got extensive knowledge of External Auditing, Internal Auditing and Financial Management. He has got good control over external auditing of national and international NGOs and commercial entities in Afghanistan.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/rahmat_k.jpeg",
  },
  {
    id: 20,
    name: "Anwar Shinwari",
    board: "Supervisor – Public Sector Audit ",
    title: "MBA and CIA",
    details:
      "Mr. Shinwari has worked in many organizations such as Afghanistan Human Rights Organization, Bank Mili Afghanistan, Ministry of Education and Ministry of Finance. He has large experience in internal auditing, training, capacity building and risk advisory services. He possesses great experience of Afghanistan Public Financial Management Law, International Professional Practices Framework (IPPF) and International Public Sector Accounting Standards. He has performed many internal audit, functional review and risk assessment in public sector entities",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/male.png",
  },

  // {
  //   id: 3,
  //   name: "Sayed Haris",
  //   title: "ACCA, BSC (Hon)-UK",
  //   details:
  //     "Sayed Haris is a Chartered Accountant (ACCA), and has got great knowledge and experience in auditing and risk advisory services. He has worked at different organizations, such as Ernst & Young (EY) and Ministry of Finance, Afghanistan. He has performed various assignments in the past such as, external audits, micro assessments, capacity assessments, fiduciary review, compliance audit, special audits, spot checks, and etc. Currently, he is working as Senior Consultant at ECA.",
  //   fb: "facebook.com",
  //   instagram: "instagram.com",
  //   img: "/img/staff/haris.jpg",
  // },

  // break point
];
