import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class ResearchSurvey extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Research and Survey Services</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div>
              <div
                className="section-title"
                data-aos="zoom-out"
                id="ENVIRONMENT"
              >
                {/* <h2>About</h2> */}
                <p
                  className="section-title"
                  style={{ fontWeight: "bold", marginBottom: "-7%" }}
                >
                  Research and Survey Services
                </p>
              </div>
            </div>

            <ul className="nav nav-tabs row d-flex">
              <p className="fs-18 m-3">
                Governments, organizations, and businesses require more
                trustworthy and precise data than ever before to understand the
                patterns and dynamics of unpredictable and complicated markets
                like Afghanistan. And that's where Eagle-Wise Chartered
                Accountants steps in, delivering such services to businesses all
                around the country.
              </p>
              <p className="fs-18 m-3">
                Our survey and research experts have extensive expertise and
                training in a variety of qualitative, quantitative, and mixed
                methods research approaches. They understand research
                methodology, data collection, data quality management, data
                analysis, and data presentation, and they complete tasks with
                amazing speed, precision, and a strict adherence to research
                ethics.
              </p>
              <p className="fs-18 m-3">
                The research and survey experts at ECA are available to help you
                with everything. We are with you every step of the way, from
                organizing your study to defining techniques, establishing
                tools, locating respondents, collecting field data, and
                reflecting on the findings.
              </p>
            </ul>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
