import React from "react";
import Helmet from "react-helmet";

const AssetManagement = () => {
  return (
    <div>
      <Helmet>
        <title>Capacity Development Methodology</title>
        <meta
          itemprop="name"
          content="Detailed list of all the services that ECA Consultency Offers"
        />
        <meta
          itemprop="description"
          content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
        />
        <meta itemprop="image" content={"/img/services/tax.jpg"} />
      </Helmet>
      <section
        id="pricing"
        className="pricing"
        style={{ backgroundColor: "#e6f2f3" }}
      >
        <div className="container">
          <div
            className="section-title"
            data-aos="zoom-out"
            // style={{ paddingTop: "%" }}
          >
            <h2>Info</h2>
            <p>Asset Management</p>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg col-md-6 p-1">
              <div className="box featured" data-aos="zoom-in">
                <ul>
                  <li className="text-left" style={{ fontSize: "16px" }}>
                    Afghanistan has been one of the most afflicted humanitarian
                    crises countries for the past four decades of ongoing war
                    and crisis. Political instability, societal unrest, and
                    natural calamities were all factors that contributed to
                    these crises. Now, with the country's recent power transfer
                    and the international community's unwillingness to accept
                    the new administration, the crisis is rapidly escalating
                    into a humanitarian catastrophe on the verge of an economic
                    breakdown that could push 97 percent of the population below
                    the poverty line. Afghans have been seriously impacted, and
                    a large number of people have been forced from their homes,
                    with nowhere to go, and are experiencing extreme weather,
                    food shortages, and other natural disasters. Consequently,
                    Eagle Wise Chartered Accountants Company intends to provide
                    certain services in the context of the present emergency
                    circumstance
                  </li>
                </ul>
                <div className="btn-wrap"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssetManagement;
