import React, { Component } from "react";
// import {Link} from 'react-router-dom'
// import Navigation2 from "../Navigation2";
// import Footer2 from "../Footer2";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import metaImg from '../../assets/img/portfolio/fullsize/1.jpg'
// import tik from "../../assets/tik.png";
import steps from "../../assets/5s.svg";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";
export default class Audit extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="mt-5">
        <Helmet>
          <title>Audit and Assurance</title>
          <meta
            itemprop="name"
            content="Detailed list of all the services that ECA Consultency Offers"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/img/services/tax.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          id="pricing"
          className="pricing"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Info</h2>
              <p>Five Steps of Our Audit</p>
            </div>

            <div className="row justify-content-center">
              <img
                src={steps}
                alt="five steps"
                width="100%"
                height="100%"
                style={{ marginBottom: 0 }}
              />
              <div className="col-lg-2 col-md-6 p-1">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  <h3 style={{ height: "100px" }}>
                    Phase I<p className="text-white ">Planning</p>
                  </h3>

                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul style={{ height: "380px" }}>
                    <li className="text-left">
                      We obtain an understanding of Client's Organization for
                      operations, control environment, accounting and financial
                      reporting systems. Together, we agree on a timetable for
                      service delivery.
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 p-1 col-md-6">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  <h3 style={{ height: "100px" }}>
                    Phase II
                    <p className="text-white ">Assessing Risk</p>
                  </h3>
                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul style={{ height: "380px" }}>
                    <li className="text-left">
                      We use our understanding of Client to identify financial
                      statement risks. We customize our audit approach to focus
                      our efforts on the financial statement risks we identify
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-1 col-md-6 mt-4 mt-md-0">
                <div
                  className="box featured "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  {/* <Link to="/services/advisory"> */}
                  <h3 style={{ height: "100px" }}>
                    Phase III
                    <p className="text-white ">
                      Evaluation/Testing Internal Control
                    </p>
                  </h3>
                  {/* </Link> */}

                  {/* <h4><sup>$</sup>19<span> / month</span></h4> */}
                  <ul style={{ height: "380px" }}>
                    <li className="text-left">
                      We evaluate whether the client significant accounting
                      applications and financial reporting systems are well­
                      controlled and whether such controls are effective. Based
                      on the result of this evaluation, we design appropriate
                      audit tests.
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-1 col-md-6 mt-4 mt-lg-0">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  {/* <Link to="/services/taxation"> */}
                  <h3 style={{ height: "100px" }}>
                    Phase IV
                    <p className="text-white ">
                      Testing Accounts and Transactions
                    </p>
                  </h3>
                  {/* </Link> */}

                  {/* <h4><sup>$</sup>29<span> / month</span></h4> */}
                  <ul style={{ height: "380px" }}>
                    <li className="text-left">
                      We perform testing and analyze client financial data. Our
                      audit programs help our teams to thoroughly and quickly
                      execute tests.
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-1 col-md-6 mt-4 mt-lg-0">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  {/* <Link to="/services/capacity-development"> */}
                  <h3 style={{ height: "100px" }}>
                    Phase V
                    <p className="text-white ">Concluding and Reporting</p>
                  </h3>
                  {/* </Link> */}

                  {/* <span className="advanced">Advanced</span> */}
                  {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                  <ul style={{ height: "380px" }}>
                    <li className="text-left">
                      In the concluding phase of the audit, we bring together
                      all the work performed by the audit team. The most
                      experienced team members confirm that the financial
                      statements make sense, based on their understanding of
                      client business. They also ensure the delivery of a high­
                      quality audit and reports.
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <NewFooter /> */}
        {/* <Footer2 /> */}
      </div>
    );
  }
}
