import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";
import v2 from "../../assets/v2.png";
import { Circle } from "react-feather";
// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class OurValues extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Our Values</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section"
          id="team"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                // style={{ marginTop: "22%" }}
                >
                  <div
                    className="section-title"
                    data-aos="zoom-out"
                    // style={{ marginTop: "8%" }}
                  >
                    {/* <h2>Our Values</h2> */}
                    {/* <p>ECA values the WIESCEL</p> */}
                    <p>Our Values</p>
                  </div>
                  <ul className="mt-1" data-aos="fade-up">
                    <li
                      style={{ marginLeft: "5%" }}
                      className="d-flex mb-2 align-items-center "
                    >
                      {/* <i
                        class="fa fa-chevron-right mr-4"
                        aria-hidden="true"
                      ></i> */}
                      <p className="fs-16" style={{ fontWeight: "bold" }}>
                        ECA values the{" "}
                        <span style={{ color: "orangered" }}>WIESCEL</span>
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <p className="fs-16">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>W</span>
                        ork Ethic
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <p className="fs-16">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>I</span>ntegrity
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <p className="fs-16">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>E</span>mployees
                        our most valuable asset
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <p className="fs-16">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>S</span>
                        ervice to our Clients & Community{" "}
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <p className="fs-16">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>C</span>
                        onfidentiality & Competency
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <p className="fs-16">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>E</span>
                        xpert business partners
                      </p>
                    </li>
                    <li
                      style={{ marginLeft: "5%" }}
                      className="d-flex mb-2 align-item-center"
                    >
                      <p className="fs-14">
                        <Circle color="orangered" size={11} className="mr-2" />
                        <span style={{ color: "orangered" }}>L</span>eadership &
                        Management
                      </p>
                    </li>
                  </ul>

                  {/* <p className="fs-18 mb-3">ECA values the WIESCEL</p>
                <p>Work Ethic</p>
                <p>Integrity</p>
                <p>Employees our most valuable asset</p>
                <p>Service to our</p>
                <p>Clients & Community</p>
                <p>Confidentiality & Competency</p>
                <p>Expert</p>
                business partners • Leadership & Management */}
                </div>
              </div>
              <div className="col-md-6">
                <img src={v2} alt="values" width="100%" height="100%" />
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}>
    
            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
