
export const RecentBlogData = [
    {
        author:"shahryar tayeb",
        posted_on:"2020/2/14",
        fb_link:"facebook.com/shahryartayeb.sh",
        id:1,
        category:"International Medical Corps(IMC)",
        img: "/img/projects/p1.jpg",
        title:"Contact For Professional Services on Taxation Matters (2020-2021)",
        blog_post_sub:""
    },
    {
        author:"shahryar tayeb",
        posted_on:"One Year",
        fb_link:"facebook.com/shahryartayeb.sh",
        id:2,
        category:"HUDA Development Organization",
        img: "/img/projects/p2.jpg",
        title:"External Audit for Year 2019",
        blog_post_sub:"Contact For Professional Services on Taxation Matters (2020-2021)"
    },
    {
        author:"shahryar tayeb",
        posted_on:"2021/8/14",
        fb_link:"facebook.com/shahryartayeb.sh",
        id:3,
        category:"Air Gateway Travel Agency",
        img: "/img/projects/p6.jpg",
        title:"Tax Compliance and Business Development Consultency(2020)",
        blog_post_sub:""
    },
    {
        author:"James Cameron",
        posted_on:"2022/8/14",
        fb_link:"facebook.com/shahryartayeb.sh",
        id:4,
        category:"Action Against Hunger (AAH)",
        img: "/img/projects/p3.jpg",
        title:"External Audit For Year 2019",
        blog_post_sub:""
    },
    {
        author:"James Cameron",
        posted_on:"2022/8/14",
        fb_link:"facebook.com/shahryartayeb.sh",
        id:5,
        category:"IBDF International",
        img: "/img/projects/p12.jpg",
        title:"External Audit For Year 2015",
        blog_post_sub:""
    },
    {
        author:"James Cameron",
        posted_on:"2022/8/14",
        fb_link:"facebook.com/shahryartayeb.sh",
        id:6,
        category:"HUDA Development Organization",
        img: "/img/projects/p1.jpg",
        title:"Tax Compliance Services 2019-2020",
        blog_post_sub:""
    }
];

