import React from "react";
// import logo from "../../assets/logo.jpg";
// import logo from "../../assets/logo.png";
// import {motion} from 'framer-motion'
import "./hover.css";
// import "./mega.css";

// import {AnimatedImg} from './Tools/FramerMotionLazy'
import { Link } from "react-router-dom";
import TopBar from "./TopBar";
export default function Navigation() {
  const handleTop = () => {
    window.scrollTo(0, 0);
  };

  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      if (document.getElementById("mainNav")) {
        document.getElementById("mainNav").style.top = "0";
        document.getElementById("mainNav").style.transition =
          "top 0.2s ease-in-out";
      }
    } else {
      if (document.getElementById("mainNav")) {
        document.getElementById("mainNav").style.top = "-68px";
        document.getElementById("mainNav").style.transition =
          "top 0.2s ease-in-out";
      }
    }
    prevScrollpos = currentScrollPos;
  };

  //

  //

  return (
    <div style={{ marginBottom: "6%" }}>
      <TopBar />
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top "
        id="mainNav"
        style={{ backgroundColor: "white", opacity: 1, marginTop: "40px" }}
      >
        <div className="container">
          <Link to="/">
            <img
              onClick={handleTop}
              // drag
              // dragConstraints={{left:0,top:0,right:0,bottom:0}}

              // initial={{opacity:0}}
              // animate={{opacity:1}}
              // transition={{duration:2}}
              style={{ height: 40 }}
              src="/logo.png"
              alt="E.C.A"
            />
          </Link>
          {/* <p>E.C.A</p> */}
          <button
            className="navbar-toggler navbar-toggler-right"
            style={{ color: "orangered", borderColor: "orangered" }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars ml-1"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ml-auto">
              <li className="nav-item dropdown" style={{ color: "white" }}>
                <a
                  className="nav-link js-scroll-trigger align-items-center"
                  href="#about"
                >
                  About Us
                  <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                </a>
                {/* submenu */}

                <ul>
                  {/* <li>
                  <a href="#about">WIESCEL</a>
                </li> */}

                  <li>
                    <Link to="/about/eca-at-a-glance">ECA at a glance</Link>
                  </li>
                  <li>
                    <Link to="/about/our-values">Our Values</Link>
                  </li>
                  {/* <li>
                  <a href="#Socio-Cultural">Socio-Cultural Forces</a>
                </li> */}
                  <li>
                    <Link to="/about/mission-and-vision">
                      Our Mission & Vision
                    </Link>
                  </li>
                  {/* <li>
                  <a href="#about">Ethics at Workplace</a>
                </li> */}
                  <li>
                    <Link to="/about/privacy-policy">Privacy</Link>
                  </li>
                </ul>

                {/* end of submenu */}
              </li>
              <li className="nav-item dropdown" style={{ color: "white" }}>
                {/* <a className="nav-link js-scroll-trigger" href="#services">
                Services
                <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i>
              </a> */}
                <span
                  className="nav-link js-scroll-trigger"
                  style={{ cursor: "pointer" }}
                >
                  Services
                  <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                </span>
                {/* submenu */}

                <ul>
                  <li>
                    <Link to="/services">Services Overview</Link>
                    {/* <a href="#"></a> */}
                  </li>
                  <li>
                    <Link to="/services/audit-assurance">
                      Audit & Assurance
                    </Link>
                    {/* <a href="#"></a> */}
                  </li>

                  <li>
                    <Link to="/services/advisory">Advisory</Link>
                    {/* <a href="#">Advisory</a> */}
                  </li>
                  <li>
                    <Link to="/services/taxation">Taxation</Link>
                    {/* <a href="#">Taxation</a> */}
                  </li>
                  <li>
                    <Link to="/services/capacity-development">
                      Capacity Development
                    </Link>
                    {/* <a href="#">Capacity Development</a> */}
                  </li>
                  <li>
                    <Link to="/services/legal-services">
                      Legal & Corporate Services
                    </Link>
                    {/* <a href="#">Capacity Development</a> */}
                  </li>
                  <li>
                    <Link to="/services/research-survey">
                      Research & Survey
                    </Link>
                    {/* <a href="#">Capacity Development</a> */}
                  </li>
                  <li>
                    <Link to="/services/asset-management">
                      Asset Management
                    </Link>
                    {/* <a href="#">Capacity Development</a> */}
                  </li>
                </ul>

                {/* end of submenu */}
              </li>

              {/* portfolio */}

              <li className="nav-item dropdown" style={{ color: "white" }}>
                <span className="nav-link " style={{ cursor: "pointer" }}>
                  Sectors
                  <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                </span>

                <ul>
                  <li>
                    <Link to="/sectors">OverView</Link>
                  </li>
                  <li>
                    <Link to="/sectors/public">Public Sector</Link>
                  </li>
                  <li>
                    <Link to="/sectors/private">Private Sector</Link>
                  </li>
                  <li>
                    <Link to="/sectors/ngo">NGO’s & Welfare</Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown" style={{ color: "white" }}>
                {/* <a className="nav-link js-scroll-trigger" href="#contact"> */}
                {/* <Link to="/contact"> */}
                {/* <a className="nav-link js-scroll-trigger" href="#"> */}
                {/* <Link to="/contact"> */}
                <Link to="/clients">
                  <span
                    className="nav-link p-0 m-0"
                    style={{ cursor: "pointer" }}
                  >
                    Clients
                    <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                  </span>
                </Link>
                <ul>
                  <li>
                    <Link to="/clients/key-clients">Key Clients</Link>
                  </li>

                  {/* <li>
                    <Link to="/clients/advisory">Advisory</Link>
                  </li>
                  <li>
                    <Link to="/clients/taxation">Taxation</Link>
                  </li> */}
                </ul>
                {/* </Link> */}
              </li>

              <li className="nav-item dropdown" style={{ color: "white" }}>
                <span className="nav-link " style={{ cursor: "pointer" }}>
                  People
                  <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                </span>
                <ul>
                  <li>
                    <Link to="/team/board-of-director">Senior Management</Link>
                  </li>
                  {/* <li>
                    <Link to="/team/professional-experts">
                      Professional Staff
                    </Link>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item dropdown" style={{ color: "white" }}>
                {/* <a className="nav-link js-scroll-trigger" href="#contact"> */}
                {/* <Link to="/contact"> */}
                {/* <a className="nav-link js-scroll-trigger" href="#"> */}
                {/* <Link to="/contact"> */}
                <Link to="/career">
                  <span
                    className="nav-link js-scroll-trigger p-0 m-0"
                    style={{ cursor: "pointer" }}
                  >
                    Careers
                    {/* <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i> */}
                  </span>
                </Link>
                {/* </Link> */}
              </li>

              <li className="nav-item dropdown" style={{ color: "white" }}>
                {/* <a className="nav-link js-scroll-trigger" href="#contact"> */}
                {/* <Link to="/contact"> */}
                {/* <a className="nav-link js-scroll-trigger" href="#"> */}
                <Link to="/contact">
                  <span
                    className="nav-link  p-0 m-0"
                    style={{ cursor: "pointer" }}
                  >
                    Contact Us
                    {/* <i class="fa fa-chevron-down ml-2" aria-hidden="true"></i> */}
                  </span>
                </Link>

                {/* </a> */}
                {/* </Link> */}
                {/* </a> */}
                {/* submenu */}

                {/* <ul> */}
                {/* <li> */}
                {/* <Link to="/contact"> */}
                {/* <a href="#"> */}
                {/* Contact Now */}
                {/* </a> */}
                {/* </Link> */}
                {/* </li> */}
                {/*                 

                <li className="m-3">
                  <p>Address:</p>
                  <p className="ml-3">
                    OFFICE #6, 4TH FLOOR Muslim Business Center Haji Yaqoob
                    Square, Shar-e-naw Kabul, Afghanistan
                  </p>
                </li>
                <li className="m-3">
                  <p>Phone:</p>
                  <p className="ml-3">+03 (0) 776 41 53 81</p>
                  <p className="ml-3">+93 (0) 708 14 68 27</p>
                </li>
                <li className="m-3">
                  <p className="ml-3">info@eca.org.af</p>
                </li>
              </ul> */}

                {/* end of submenu */}
                {/* Contact */}
              </li>
              {/*  */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
