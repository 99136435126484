export const Finance = [
  {
    id: 15,
    name: "Ashiq Muhammad",
    board: "Research & Survey Expert",
    title: "BA & MPA",
    details:
      "Mr. Ashiq has obtained training certifications of Fundamentals of Project Management, Monitoring & Evaluation, Disaster Risk Reduction, Qualitative Research Methodology, and Community Based Disaster Management and Preparedness. He has extensive experience of conducting different researches for last 20 years. Mr. Ashiq has conducted numerus assignments of national and international organization in Afghanistan, Pakistan and UAE. He has good command on working with Afghanistan Government, NGOs and Private Sector.  ",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/male.png",
  },
  {
    id: 14,
    name: "Rahmatullah",
    board: "Research & Survey Consultant",
    title: "Bachelor in statistics",
    details:
      "Mr. Rahmatullah has worked with different national and international organizations. He has extensive knowledge in sampling both qualitative and quantitative data analysis. He has managed different engagements of Afghanistan Government, NGOs, and private sector. ",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/rahmat_u.jpeg",
  },
];
