import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";
import conf from "../../assets/img/conf.png";
// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class Confident extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Confidentiality</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div class="row no-gutters">
              <div class="col-md-7 my-auto showcase-text" id="Confidentiality">
                <h2>Confidentiality</h2>
                <p class="lead mb-0">
                  Confidentiality refers to maintaining information safe
                  concerning to clients, and not disclosing it to others, such
                  as competitors, coworkers or to any other stakeholders without
                  the consent of clients.
                </p>

                <p class="lead mb-0">Maintaining confidentiality includes:</p>

                <p className="ml-4 fs-18">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  ECA will develop and implement an information security policy
                  to impose a uniform set of rules for handling and protecting
                  essential data.
                </p>
                <p className="ml-4 fs-18">
                  <i class="fa fa-chevron-right mr-2 " aria-hidden="true"></i>
                  Every piece of information ECA holds has value, whether it’s
                  financial data, credit card numbers, trade secrets, personal
                  information or legal documents.
                </p>
                <p className="ml-4 fs-17">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  Individual files are locked and secured
                </p>
                <p className="ml-4 fs-17">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  Names and addresses of clients
                </p>
                <p className="ml-4 fs-17">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  Phone numbers and addresses of staff and volunteers
                </p>
                <p className="ml-4 fs-17">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  Names and personal details of people who donate money or time
                </p>
                <p className="ml-4 fs-17">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  Details of funding agreements
                </p>
                <p className="ml-4 fs-17">
                  <i class="fa fa-chevron-right mr-2" aria-hidden="true"></i>
                  Information about strategic planning.
                </p>
              </div>
              <div
                class="col-md-5 text-white showcase-img"
                // style={{
                //   backgroundImage: require("../../assets/img/img-01.png"),
                // }}
              >
                <img
                  src={conf}
                  alt="adv"
                  //   width="100%"
                  //   height="100%"
                  className="img-fluid"
                  data-aos="zoom-in"
                />
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
