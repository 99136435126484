import React ,{Suspense,lazy} from 'react';

// Lazy import Framer Motion for div
const MotionDiv = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.div,
    }))
);

export const AnimatedDiv = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionDiv {...props} />
    </Suspense>
)

// Lazy import Framer Motion for Button
const MotionButton = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.button,
    }))
);

export const AnimatedButton = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionButton {...props} />
    </Suspense>
) 


// Lazy import Framer Motion for P
const MotionP = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.p,
    }))
);

export const AnimatedP = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionP {...props} />
    </Suspense>
) 

// Lazy import Framer Motion for img
const MotionImg = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.img,
    }))
);

export const AnimatedImg = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionImg {...props} />
    </Suspense>
) 

// Lazy import Framer Motion for h2
const MotionH2 = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.h2,
    }))
);

export const AnimatedH2 = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionH2 {...props} />
    </Suspense>
) 


// Lazy import Framer Motion for h3
const MotionH3 = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.h2,
    }))
);

export const AnimatedH3 = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionH3 {...props} />
    </Suspense>
) 

// Lazy import Framer Motion for a
const MotionA = lazy(()=>
    import('framer-motion').then(mod=>({
        default:mod.motion.a,
    }))
);

export const AnimatedA = props => (
    <Suspense fallback={<div className={props.className}>{props.children}</div>}>
        <MotionA {...props} />
    </Suspense>
) 