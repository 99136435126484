export const Taxation = [
  {
    id: 565,
    name: "Qais Muhammad",
    board: "Tax Consultant",
    title: "BBA",
    details:
      "Mr. Qais Muhammad has worked with different organization as tax consultant. He has a very good understanding of tax law and procedures. He has experience of working on different engagements Afghanistan Government, NGOs, donor community and private sector.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/qais.jpg",
  },

  {
    id: 20,
    name: "Ziarat Gul",
    board: "Tax Consultant",
    title: "BBA",
    details:
      "Mr. Ziarat Gul has worked on different engagements of commercial clients in the Private sector.  He has a very good experience and understanding of tax procedures and preparation of documents. He has experience of preparing documents for monthly compliances, quarterly BRT and annual tax return",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/ziarat.jpeg",
  },
  {
    id: 40,
    name: "Mariam Sherzad",
    board: "Tax Consultant",
    title: "BBA",
    details:
      "Ms. Mariam has a good experience of submitting monthly, quarterly and annual returns. She has a good skill of preparing documents for monthly compliances, quarterly BRT and annual tax return.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/mariam.jpeg",
  },
  // {
  //   id: 30,
  //   name: "Noor Rehman",
  //   board: "Tax Consultant",
  //   title: "BBA",
  //   details:
  //     "CPA ( England and  Wales) Master Economics and B.com from the University of Peshawar (Pakistan) Noor is working as Advisor with ECA Afghanistan. Previously, he has been working with 3 of the big fours (Deloitte, PWC-afghanistan & KPMG­ Afghanistan) and Grant Thornton International in Afghanistan. He is one of the prominent persons in kabul for tax and corporate matters. Noor has 15 years’ experience of dealing in tax and corporate matters in Afghanistan. He is well acquainted with working environment and legal practices prevailing in Afghanistan. He has been Tax manager for 2 years in Afghan Wireless communication company (AWCC) which is a leading and the largest entity in telecom sector of the country. He has  maintained professional as well as personal relations with key of personnel at tax offices.",
  //   fb: "https://www.facebook.com/noor.rehman.5851",
  //   linked: " https://www.linkedin.com/in/noor-rehman-khattak-73b84520",
  //   instagram: "https://www.linkedin.com/in/noor-rehman-khattak-73b84520",
  //   img: "/img/staff/noor.jpg",
  // },
];
