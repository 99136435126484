export const BOD = [
  {
    id: 8,
    name: "Foulad Khan Shinwary",
    title: "CPA, ACCA(Progress), MBA-Finance",
    board: "President-CEO/Tax Partner",
    details:
      "Mr. Foulad Khan is a certified legal tax consultant from the Afghanistan Ministry of Finance (AMOF). He is the president and tax partner of the Eagle Wise Chartered Accountants Company and has significant experience collaborating with both domestic and international organizations in Afghanistan. He also has expertise in directing the academic role of the universities in Afghanistan.",
    fb: " https://www.facebook.com/profile.php?id=100004818422964",
    instagram: "instagram.com",
    img: "/img/staff/foulad.jpg",
  },
  // {
  //   id: 15,
  //   name: "Sayed Yawar Sadat",
  //   board: "Partner & Vice President",
  //   title: "BBA",
  //   details:
  //     "Mr. Sayed Yawar has worked on different engagements of external auditing, internal auditing, Taxation and advisory services. He has worked with national and international organizations since 2014.  He has good experience in External Auditing, Taxation, and Financial Management in Afghanistan Government, NGOs, donor community and private sector.",
  //   fb: "facebook.com",
  //   instagram: "instagram.com",
  //   img: "/img/staff/yawar.jpg",
  // },
  {
    id: 6,
    name: "Mr.Nauroz Khan",
    board: "Vice President/Managing Director/Business Development Consultant",
    title: "MPhil Finance",
    details:
      "Mr. Nauroz Khan has substantial expertise in the field of business development, management, and capacity building. He has wide experienceworking with Afghan government ministries, and national and international NGOs over the last ten years. Apart from being an ECA shareholder, he has been assisting the business development department at Eagle-Wise Chartered Accountants Company.",
    fb: "https://www.facebook.com/naurozkhan89",
    linked: " https://www.linkedin.com/in/nauroz-khan-7a2a1881",
    instagram: "https://www.linkedin.com/in/nauroz-khan-7a2a1881",
    img: "/img/staff/nauroz.jpg",
  },

  {
    id: 30,
    name: "Mr.Noor Rehman",
    board: "Partner-Tax/Executive Director",
    title: "CPA-UK, B.com & Master in Economics ",
    details:
      "Mr. Noor Rehman has worked with 3 of the big four audit firms (Deloitte, PWC-Afghanistan, KPMG-Afghanistan, and Grant Thornton International) inAfghanistan since 2005. He has extensive experience in tax compliance, taxadvisory, and corporate services with national and international organizations. Mr. Noor Rehman is currently working virtually as an Executive director – of Tax & Corporate Services with ECA.",
    fb: "https://www.facebook.com/noor.rehman.5851",
    linked: " https://www.linkedin.com/in/noor-rehman-khattak-73b84520",
    instagram: "https://www.linkedin.com/in/noor-rehman-khattak-73b84520",
    img: "/img/staff/noor.jpg",
  },

  {
    id: 16,
    name: "Mr.Zahidullah Hammad",
    board: "Partner/Finance & Admin",
    title: "M.Com",
    details:
      "Mr. Zahidullah has significant experience in management, dministration, internal control, and consulting services. He has orked with a range of localand international organizations in fghanistan as part of his professional expertise. He is the Admin f Eagle Wise Chartered Accountants in additionto being an ECA artner.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/zahid.jpeg",
  },
  {
    id: 223,
    name: "Mr. Siddiqullah",
    board: "Senior Manager-Audit &Assurance",
    title: "ACCA",
    details:
      "Mr. Siddiqullah is a member of the Association of Chartered Certified Accountants (ACCA) with over ten years of professional and advanced job experience in auditing, accounting services, training, and development. He has extensive experience working with national and international organizations around the world",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/siddiqullah.png",
  },
  {
    id: 1655,
    name: "Mr. Bakht Rawan",
    board: "Partner-Tax/Director",
    title: "CPA, M. Com",
    details:
      "Bakht is currently employed by Eagle Wise Chartered Accountants as Director of Tax & Corporate Services. For the previous three years, he worked for one of the Big Four audit and tax firms (Deloitte) and RSM International. He is a well-known expert in Kabul for tax and corporate concerns. Bakht has almost a decade of expertise dealing with tax and corporate issues. He is well-versed in Afghanistan's working environment as well as its legal norms. He has maintained professional and personal relationships with key tax office officials.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/bakht.png",
  },

  // {
  //   id: 1,
  //   name: "Sayed Haris",
  //   board: "Audit Manager – Audit & Assurance ",
  //   title: "ACCA & BSC from Oxford Brookes University ",
  //   details:
  //     "Mr. Haris has worked in Ernst & Young (EY) and Ministry of Finance, Afghanistan. He has great knowledge and experience in External Auditing, Internal Auditing and Risk Advisory Services. He has done various assignments in the past such as, external audits, micro assessments, risk assessments, capacity assessments, fiduciary review, functional reviews, compliance audits, special audits and spot checks. His audit experience spreads over a variety of organizations with special emphasis on compliance with International Financial Reporting Standards, compliance with International Standards on Auditing, reporting on contractual compliances and donor guidelines, and verifications in different public sector entities, national and international organizations.",
  //   fb: "facebook.com",
  //   instagram: "#",
  //   img: "/img/staff/haris.jpg",
  // },
];
