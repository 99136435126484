import React, { Component } from "react";
import { Circle } from "react-feather";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class OverView extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Sectors</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div>
              <div
                className="section-title"
                data-aos="zoom-out"
                style={{ marginBottom: "-5%" }}
                id="ENVIRONMENT"
              >
                {/* <h2>About</h2> */}
                <p className="section-title" style={{ fontWeight: "bold" }}>
                  Sectors
                </p>
              </div>
            </div>

            <ul className="nav nav-tabs row d-flex">
              <p className="fs-18 m-3">
                To boost the overall business enabling environment and help
                entrepreneurs and micro, small, and medium companies (MSMEs)
                thrive, ECA collaborates with private business incubators and
                accelerators, as well as firms of all sizes. ECA assisted
                entrepreneurs and MSMEs in gaining access to capital and vital
                services, as well as having precise financial documentation and
                clear taxation, via its support and services. Furthermore, ECA
                collaborate with local enterprises to provide consulting and
                training services in order to help organizations and employees
                develop their potential.
              </p>
              <p className="fs-18 m-3">
                Eagle-Wise chartered Accountants has the privileged of working
                with public sector of the country. Public enterprises, often
                known as state-owned firms, are self-financing commercial
                enterprises controlled by the government that offer a variety of
                private goods and services. ECA has worked with some of the
                ministries of Afghanistan and are still in progress to achieve
                various projects of the government and provide the best quality
                services to the government and especially to inhabitants of the
                country.
              </p>
              <p className="fs-18 m-3">
                Eagle-Wise chartered Accountants is also working with various
                NGO’s and Welfare organizations across Afghanistan. ECA provide
                their own services to them and help them in every aspect of
                their functions. ECA also tend to work with charity
                organizations and provide their own services at very low or less
                costs.
              </p>
              <p className="fs-18 m-3">
                ECA provide Audit, Taxation and Advisory services as per the
                applicable Laws, Regulations, FIRSs, IASs, ISAs, IPSAS, IPPFs’
                Standards to the below sectors at large.
              </p>
            </ul>
            <ul className="mt-3" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-4">
                  <ul>
                    <li
                      style={{ marginLeft: "5%" }}
                      className="d-flex mb-2 align-items-center"
                    >
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Manufacturing.</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Mining and energy</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Oil and gas</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={15} className="mr-2" />
                      <p className="fs-16">
                        Real estate, land development and property management
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Financial institutions</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Professional services</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Insurance companies</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-14">Donors community</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li
                      style={{ marginLeft: "5%" }}
                      className="d-flex mb-2 align-items-center"
                    >
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Information technology</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Vehicle financing and leasing</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Wholesale and retail trade</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Construction</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Food and beverage</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Entertainment</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Telecommunications</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-14">NGOs</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li
                      style={{ marginLeft: "5%" }}
                      className="d-flex mb-2 align-items-center"
                    >
                      <Circle color="orangered" size={16} className="mr-2" />
                      <p className="fs-16">
                        Not-for-profit and charities, including sports
                        organizations, churches, etc
                      </p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Retail</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Health care</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Pharmaceutics</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Public Sectors organization</p>
                    </li>
                    <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                      <Circle color="orangered" size={11} className="mr-2" />
                      <p className="fs-16">Demanded clients</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
