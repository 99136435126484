import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ButtonContainer } from "./Button";
import "../../css/projectdetails.css";
// import ImageGalleryInside from '../../components/ImageGalleryInside'
// import Navigation2 from "../Navigation2";
// import Footer2 from "../Footer2";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";

// import { RecentBlogData } from "../about_data";
import { RecentBlogData } from "../../data/about_data";
// import NewFooter from "../HoverNav/NewFooter";
// import Navigation from "../HoverNav/Navigation";

const ImageGalleryInside = React.lazy(() =>
  import("../../components/ImageGalleryInside")
);

export default function Details() {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    RecentBlogData.forEach((data) => {
      if (data.id === parseInt(id)) {
        setData(data);
      }
    });
  });

  // console.log(id);

  const { title, blog_post_sub, category, posted_on } = data;
  return (
    <div style={{ backgroundColor: "#e6f2f3" }}>
      <Helmet>
        <title>{title}</title>
        <meta
          itemprop="name"
          content="Detailed information of all the Project Completed by Eagle Wise Chartered Accountants."
        />
        <meta itemprop="description" content={blog_post_sub} />
        <meta itemprop="image" content={"/img/projects/p12.jpg"} />
      </Helmet>

      {/* <Navigation2 /> */}
      {/* <Navigation /> */}
      <div className="container py-5">
        {/* title */}
        <div className="row">
          <div className="col-10 mx-auto text-center text-slanted text-blue my-5">
            <h1>{category}</h1>
          </div>
        </div>
        {/* end of title */}

        {/* product info */}
        <div className="row">
          {/* product image */}
          <div className="col-10 mx-auto col-md-6 my-3 ">
            {/* <img src={img} alt="hello" className="img-fluid"/> */}
            {/* place for the slide show */}
            <Suspense fallback={<div>Loading...</div>}>
              <ImageGalleryInside />
            </Suspense>
            {/* end of place for the slideshow */}
          </div>
          {/* product text */}
          <div className="col-10 mx-auto col-md-6 my-3 text-capatalize">
            <h2>Project: {title} </h2>
            <h4 className="text-title text-uppercase text-muted mt-3 mb-2">
              Duration : <span className="text-uppercase">{posted_on}</span>
            </h4>
            <h4 className="text-blue">
              <strong>{/* Price: <span>$</span>{price} */}</strong>
            </h4>
            <p className="text-capatalize font-weight-bold mt-3 mb-0">
              Some info about the project:
            </p>
            <p className="text-muted lead">{blog_post_sub}</p>
            {/* Buttons */}
            <div>
              <Link to="/">
                <ButtonContainer>Back to Site</ButtonContainer>
              </Link>
              <Link to="/contact">
                <ButtonContainer>More Info</ButtonContainer>
              </Link>
            </div>
          </div>
        </div>
        {/* end of product info */}
      </div>
      {/* <Footer2/> */}
      {/* <NewFooter /> */}
    </div>
  );
}
