import React, { Suspense } from "react";
import Masthead from "../components/Masthead";
import "../css/styles.css";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
// import Gallery from "../components/Gallery";
const Info = React.lazy(() => import("./NewCom/Info"));
const Blog = React.lazy(() => import("../components/Blog"));
const Service = React.lazy(() => import("../components/Service"));
const CTA = React.lazy(() => import("../components/CTA"));
const Contact = React.lazy(() => import("../components/Contact"));

export default function MainPage() {
  // useEffect(() => {
  //   Aos.init({ duration: 2000, mirror: true });
  // }, []);

  return (
    <div className="page-top">
      <Helmet>
        <title>Eagle Wise Chartered Accountants Company</title>
        <meta
          itemprop="name"
          content="Eagle Wise Chartered Accountants Company"
        />
        <meta
          itemprop="description"
          content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul, Afghnistan proudly helping Small and Large buisnesses to grow and Achieve their Business Goals."
        />
        <meta itemprop="image" content={"/logo.jpg"} />
      </Helmet>
      {/* <Navigation></Navigation> */}
      {/* <HoverNav /> */}
      <Masthead></Masthead>
      {/* <ImageGallery/> */}
      <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        {/* <About></About> */}
        {/* <ReactLoading className="bubbles" style={{marginLeft:"40%",height:"20%",width:"20%"}} color="orangered" type="bubbles"  /> */}
        <Info />
      </Suspense>
      {/* <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <Gallery />
      </Suspense> */}
      <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <Service></Service>
      </Suspense>
      {/* <Suspense fallback={<ReactLoading className="bubbles" style={{marginLeft:"40%",height:"20%",width:"20%"}} color="orangered" type="bubbles"  />}>
                <Portfolio></Portfolio>
            </Suspense> */}
      <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <CTA></CTA>
      </Suspense>
      <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <Blog />
      </Suspense>
      {/* <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <Clients></Clients>
      </Suspense> */}
      {/* <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <Team />
      </Suspense> */}

      <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
        <Contact></Contact>
      </Suspense>
      {/* <Suspense
        fallback={
          <ReactLoading
            className="bubbles"
            style={{ marginLeft: "40%", height: "20%", width: "20%" }}
            color="orangered"
            type="bubbles"
          />
        }
      >
     
        <NewFooter />
      </Suspense> */}
      {/* <Contacts2></Contacts2> */}

      {/* <Footer></Footer> */}
    </div>
  );
}
