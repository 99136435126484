import React from "react";

// import "../../css/main.css";
// import "../css/util.css";
// import img from "../../assets/img/img-01.png";
import "./contact.css";
// import Footer2 from "./Footer2";
// import Navigation from './Navigation'
// import { Link } from "react-router-dom";
// import Navigation2 from "./Navigation2";

import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// import LazyLoad from "react-lazyload";

// const ServiceID = "service_ne2mi6e"
// const TemplateID = "template_j4kk338"
// const UserID = "user_vNut7TA3ILedNQOLWfF9Z"
import { Helmet } from "react-helmet";
// import { ServiceID, TemplateID, UserID } from "../../Tools/EmailJsAuth";
import {
  ServiceID,
  TemplateID,
  UserID,
} from "../../components/Tools/EmailJsAuth";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";
// import metaImg from '../assets/img/portfolio/fullsize/2.jpg'

export default function ContactPage() {
  // componentDidMount(){
  // 	window.scrollTo(0,0)
  // }

  const { register, errors, handleSubmit, reset } = useForm();

  const toastifySuccess = () => {
    toast.success("Sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const toastifyFail = (e) => {
    toast.error(e.message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback fail",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    // console.log("Name: ",data.name);
    // console.log("Email: ",data.email);
    // console.log("Subject: ",data.subject?data.subject:"");
    // console.log("message: ",data.message);

    // console.log(ServiceID);

    // document.getElementById("myForm").submit();
    try {
      const templateParams = {
        name: data.name,
        email: data.email,
        subject: data.subject ? data.subject : "",
        message: data.message,
      };
      await emailjs.send(ServiceID, TemplateID, templateParams, UserID);
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
      toastifyFail(e);
    }
  };

  return (
    <div className="mt-5">
      <Helmet>
        <title>Contact ECA</title>
        <meta
          itemprop="name"
          content="Get in touch with Eagle Wise Chartered Accountants"
        />
        <meta
          itemprop="description"
          content="Eagle Wise Chartered Acountants wil get back to as soon as possible."
        />
        <meta itemprop="image" content={"/img/services/capa.jpg"} />
      </Helmet>

      {/* <Navigation2 /> */}
      {/* <Navigation /> */}

      <section
        id="contact"
        className="contact
      "
        style={{ marginTop: "11%", marginBottom: "5%" }}
      >
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <i class="fa fa-map" aria-hidden="true"></i>
                {/* <i className="bx bx-map"></i> */}
                <h3>Our Address</h3>
                <p className="fs-14">
                  OFFICE #6, 4TH FLOOR Muslim Business Center Haji Yaqoob
                  Square, Shar-e-naw Kabul, Afghanistan
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                {/* <i className="bx bx-envelope"></i> */}
                <h3>Email Us</h3>
                <p>info@eca.org.af</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="info-box  mb-4">
                <i class="fa fa-phone-square" aria-hidden="true"></i>
                {/* <i className="bx bx-phone-call"></i> */}
                <h3>Call Us</h3>
                <p>+93 (0) 20 22 02 179</p>
                <p>+93 (0) 798 87 17 45</p>
              </div>
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6 ">
              <iframe
                title="map"
                class="gmap_iframe"
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=haji yaqub square kabul afghanistan&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>

              {/*                 
              <iframe
                title="map"
                className="mb-4 mb-lg-0"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                frameborder="0"
                style={{ border: 0, width: "100%", height: "384px" }}
                // style="border:0; width: 100%; height: 384px;"
                allowfullscreen
              ></iframe> */}
            </div>

            <div className="col-lg-6">
              <form
                className="php-email-form"
                onSubmit={handleSubmit(onSubmit)}
                id="myForm"
              >
                <div className="form-row">
                  <div className="col form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      ref={register({
                        required: {
                          value: true,
                          message: "Please Enter Your Name",
                        },
                        maxLength: {
                          value: 40,
                          message: "Please use 40 Character or Less",
                        },
                      })}
                    />
                    {errors.name && (
                      <span className="errorMessage">
                        {errors.name.message}
                      </span>
                    )}
                    <div className="validate"></div>
                  </div>
                  <div className="col form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                    />
                    {errors.email && (
                      <span className="errorMessage">
                        Please enter a valid email address
                      </span>
                    )}
                    <div className="validate"></div>
                  </div>
                </div>
                {/* <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.message && (
                    <span className="errorMessage">Please enter a message</span>
                  )}
                  <div className="validate"></div>
                </div> */}
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Message"
                    ref={register({
                      required: true,
                    })}
                  ></textarea>
                  {errors.message && (
                    <span className="errorMessage">Please enter a message</span>
                  )}
                  <div className="validate"></div>
                </div>
                {/* <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div> */}
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </section>
      {/* <Footer2 /> */}
      {/* <NewFooter /> */}
    </div>
  );
}
