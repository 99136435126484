import React, { Component, Suspense } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class SocialActivity extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Social Activity</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div
              className="row content"
              // style={{ marginBottom: "-7%" }}
              data-aos="fade-up"
            >
              <div className="col-md-6" id="social-activity">
                {/* <div style={{ marginTop: "22%" }}> */}
                <div
                  className="section-title"
                  data-aos="zoom-out"
                  style={{ marginBottom: "-60px", marginTop: "9%" }}
                >
                  {/* <h2>About</h2> */}
                  <p className="section-title" style={{ fontWeight: "bold" }}>
                    Social Activities
                  </p>
                </div>

                <p className="fs-18 mt-3">
                  Eagle Wise Chartered Accountants is a firm that besides
                  offering its services to the public also focuses on benefits
                  the environment, surrounding, and inhabitants by providing
                  social activities. The ECA can provide its services to anyone
                  of any religion or ethnicity. In terms of gender, everyone is
                  welcome at Eagle Wise Chartered Accountants, and they will be
                  provided with a variety of services they need or request for.
                  It's worth noting that ECA won't collaborate with
                  organizations that oppose the government, the law, or any
                  particular religion or ethnicity in the country, but they will
                  help businesses that help in community growth. Following are
                  the steps taken by ECA for the community growth.
                </p>
                {/* </div> */}
              </div>
              <div className="col-md-6">
                <ul className="mt-3" data-aos="fade-up">
                  <li
                    style={{ marginLeft: "5%" }}
                    className="d-flex mb-2 align-items-center"
                  >
                    <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                    <p className="fs-16">
                      The ECA will provide discounts to charitable organizations
                      and others who contribute to the community's growth.
                    </p>
                  </li>
                  <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                    <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                    <p className="fs-16">
                      ECA conducts monthly or weekly seminars and gatherings to
                      inform citizens and organizations about the clearance and
                      significance of Tax, Audit, and other organizational
                      issues.
                    </p>
                  </li>
                  <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                    <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                    <p className="fs-16">
                      ECA would provide a helping hand to people who have been
                      on the receiving end of disasters such as floods, blasts,
                      and pandemics by providing them with a free meal and
                      financial assistance.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
