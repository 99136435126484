import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";
import pri from "../../assets/img/pri.png";
import { Circle } from "react-feather";
// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import ShowCase from "../NewCom/ShowCase";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Privacy Policy</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section"
          id="team"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="row no-gutters px-5 mb-5">
            {/* <div
              class="col-md-5 order-lg-2 text-white showcase-img align-items-center"
              // style={{
              //   backgroundImage: require("../../assets/img/img-01.png"),
              // }}
            >
              <img
                src={pri}
                alt="adv"
                //   width="70%"
                //   height="70%"
                // className="img-fluid"
                data-aos="zoom-in"
              />
            </div> */}
            <div
              class="col-lg-12 order-lg-1 my-auto showcase-text"
              id="Privacy"
            >
              <h2>Privacy Policy: </h2>
              <p class="lead mb-3">
                Eagle Wise Chartered Accountants value client’s privacy. Any
                information pertaining to the operations of your organization
                will be held strictly confidential. We require strict adherence
                by all our staff to the ethical rules of our profession. In all
                respects of our practice, the firm and its employees maintain
                strict confidentiality towards information obtained while
                carrying out our professional duties. In addition, the firm and
                its people maintain complete independence of interest and mental
                attitude in relationships with clients. We endeavor to provide
                quality and transcendent services to all our clients.
              </p>
              <p class="lead mb-3">
                Our commitment to our clients and others is to respect and
                protect the privacy and confidentiality of personal and business
                information that is entrusted to us in the course of rendering
                professional services to our clients. Each and every one of our
                employees, partners and contractors is responsible for
                maintaining the confidentiality and security of all information
                to which they have access. Our employees are trained to know and
                respect our Privacy Policy, and compliance with our policies and
                procedures for protecting personal information is a condition of
                their employment.
              </p>
              <p class="lead mb-3">
                The Personal Information Protection and Electronic Documents Act
                (PIPEDA) governs how all private sector organizations handle
                personal information of clients, employees, and other. Personal
                information is defined as any information that can identify an
                individual and information about an identifiable individual.
                This includes information such as a person’s home address, date
                of birth, social insurance number, medical and financial
                information.
              </p>

              <p className="lead mb-3">
                The ECA’s partners, staff and associates are governed by the
                Rules of Professional Conduct maintained by the IFAC. These
                rules also set standards for the collection and use of personal
                information
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mt-3">
              <h2 className="ml-5">
                {" "}
                <Circle color="orangered" size={18} className="mr-2" />
                Recourse{" "}
              </h2>
              <p className="lead mb-3" style={{ marginLeft: "11%" }}>
                If you have concerns or complaints regarding your personal
                information or our Privacy Policy, please contact our Privacy
                and Confidentiality leader in writing by email or mail:
              </p>

              <p className="lead mb-3" style={{ marginLeft: "11%" }}>
                <span style={{ fontWeight: "bold" }}>Phone:</span> +93 (0) 776
                41 53 81
              </p>
              <p className="lead mb-3" style={{ marginLeft: "11%" }}>
                <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                privacy@eca.org.af
              </p>
              <p className="lead mb-3" style={{ marginLeft: "11%" }}>
                <span style={{ fontWeight: "bold", marginRight: 8 }}>
                  Address:
                </span>
                OFFICE #6, 4TH FLOOR Muslim Business Center Haji Yaqoob Square,
                Shar-e-naw Kabul, Afghanistan
              </p>
            </div>
            <div className="col-lg-6 mt-3">
              <img
                src={pri}
                alt="adv"
                //   width="70%"
                //   height="70%"
                // className="img-fluid"
                data-aos="zoom-in"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <p className="lead  m-5" style={{ marginLeft: "11%" }}>
                The Privacy and Confidentiality leader will ensure that a
                complete investigation of a complaint is undertaken and will
                report the results of this investigation, in most cases, within
                20 working day.
              </p>
            </div>
          </div>

          {/* <Suspense fallback={<div>Loading...</div>}>
    
            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
