import React, { Component } from "react";
// import "../../css/team.css";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

// import { BOD } from "./BOD_Data.js";
import { Audit } from "../../data/Audit.js";
import { Taxation } from "../../data/Taxation.js";

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
import { sloVariants, hVariants } from "../../components/Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2 } from "../Tools/FramerMotionLazy";
import {
  AnimatedDiv,
  AnimatedH2,
} from "../../components/Tools/FramerMotionLazy";
import { Capacity } from "../../data/Capacity";
import { Finance } from "../../data/Finance";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class DepartmentWise extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>ECA Team</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}

        <section
          className="page-section"
          id="team"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="text-center mb-4">
              <AnimatedH2
                variants={hVariants}
                initial="hidden"
                animate="visible"
                className="section-heading text-uppercase mb-4 "
              >
                Audit and Assurance Department
              </AnimatedH2>
              <br />
              {/* <AnimatedH3
                variants={hVariants}
                initial="hidden"
                animate="visible"
                className="section-subheading text-muted "
              >
                Our team consists of Amazing and talented youth
              </AnimatedH3> */}
            </div>
            <div className="row mt-4">
              {Audit.map((data) => {
                const linkName = this.state.readMore
                  ? "Read Less << "
                  : "Read More >> ";

                return (
                  <AnimatedDiv
                    key={data.id}
                    variants={sloVariants}
                    initial="hidden"
                    animate="visible"
                    className="col-lg-4 "
                  >
                    <div className="team-member">
                      <img
                        className="mx-auto rounded-circle"
                        src={data.img}
                        alt={data.title}
                      />
                      <h4>{data.name}</h4>
                      <p className="text-muted">{data.title}</p>
                      {this.state.readMore
                        ? data.details
                        : `${data.details.substring(0, 150)}...`}
                      <br />
                      <button
                        onClick={() => {
                          this.setState({ readMore: !this.state.readMore });
                        }}
                      >
                        <p style={{ color: "orangered", marginBottom: "10%" }}>
                          {linkName}
                        </p>
                      </button>
                      <br />
                      {/* <p className="text-muted">{data.details}</p> */}
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.instagram}
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </AnimatedDiv>
                );
              })}
            </div>

            <AnimatedH2
              variants={hVariants}
              initial="hidden"
              animate="visible"
              className="section-heading text-uppercase text-center"
            >
              Taxation Department
            </AnimatedH2>
            <br />

            {/*  */}

            <div className="row mt-4">
              {Taxation.map((data) => {
                const linkName = this.state.readMore
                  ? "Read Less << "
                  : "Read More >> ";

                return (
                  <AnimatedDiv
                    key={data.id}
                    variants={sloVariants}
                    initial="hidden"
                    animate="visible"
                    className="col-lg-4 "
                  >
                    <div className="team-member">
                      <img
                        className="mx-auto rounded-circle"
                        src={data.img}
                        alt={data.title}
                      />
                      <h4>{data.name}</h4>
                      <p className="text-muted">{data.title}</p>
                      {this.state.readMore
                        ? data.details
                        : `${data.details.substring(0, 150)}...`}
                      <br />
                      <button
                        onClick={() => {
                          this.setState({ readMore: !this.state.readMore });
                        }}
                      >
                        <p style={{ color: "orangered", marginBottom: "10%" }}>
                          {linkName}
                        </p>
                      </button>
                      <br />
                      {/* <p className="text-muted">{data.details}</p> */}
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.instagram}
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </AnimatedDiv>
                );
              })}
            </div>
            <AnimatedH2
              variants={hVariants}
              initial="hidden"
              animate="visible"
              className="section-heading text-uppercase text-center"
            >
              Capacity Development and Advisory
            </AnimatedH2>
            <br />

            {/*  */}

            <div className="row mt-4">
              {Capacity.map((data) => {
                const linkName = this.state.readMore
                  ? "Read Less << "
                  : "Read More >> ";

                return (
                  <AnimatedDiv
                    key={data.id}
                    variants={sloVariants}
                    initial="hidden"
                    animate="visible"
                    className="col-lg-4 "
                  >
                    <div className="team-member">
                      <img
                        className="mx-auto rounded-circle"
                        src={data.img}
                        alt={data.title}
                      />
                      <h4>{data.name}</h4>
                      <p className="text-muted">{data.title}</p>
                      {this.state.readMore
                        ? data.details
                        : `${data.details.substring(0, 150)}...`}
                      <br />
                      <button
                        onClick={() => {
                          this.setState({ readMore: !this.state.readMore });
                        }}
                      >
                        <p style={{ color: "orangered", marginBottom: "10%" }}>
                          {linkName}
                        </p>
                      </button>
                      <br />
                      {/* <p className="text-muted">{data.details}</p> */}
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.instagram}
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </AnimatedDiv>
                );
              })}
            </div>
            <AnimatedH2
              variants={hVariants}
              initial="hidden"
              animate="visible"
              className="section-heading text-uppercase text-bold mb-5 text-center"
            >
              Research and Survey
            </AnimatedH2>
            <br />
            <div className="row mt-4">
              {Finance.map((data) => {
                const linkName = this.state.readMore
                  ? "Read Less << "
                  : "Read More >> ";

                return (
                  <AnimatedDiv
                    key={data.id}
                    variants={sloVariants}
                    initial="hidden"
                    animate="visible"
                    className="col-lg-4 "
                  >
                    <div className="team-member">
                      <img
                        className="mx-auto rounded-circle"
                        src={data.img}
                        alt={data.title}
                      />
                      <h4>{data.name}</h4>
                      <p className="text-muted">{data.title}</p>
                      {this.state.readMore
                        ? data.details
                        : `${data.details.substring(0, 150)}...`}
                      <br />
                      <button
                        onClick={() => {
                          this.setState({ readMore: !this.state.readMore });
                        }}
                      >
                        <p style={{ color: "orangered", marginBottom: "10%" }}>
                          {linkName}
                        </p>
                      </button>
                      <br />
                      {/* <p className="text-muted">{data.details}</p> */}
                      {/* <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-twitter"></i>
                      </a> */}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-dark btn-social mx-2"
                        href={data.instagram}
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </AnimatedDiv>
                );
              })}
            </div>

            {/*  */}
          </div>

          {/* <Suspense fallback={<div>Loading...</div>}>

            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
