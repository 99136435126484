import React, { Component } from "react";
// import {Link} from 'react-router-dom'
// import Navigation2 from "../Navigation2";
// import Footer2 from "../Footer2";
import { Helmet } from "react-helmet";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";
// import { Link } from "react-router-dom";
// import metaImg from '../../assets/img/portfolio/fullsize/1.jpg'
// import tik from "../../assets/tik.png";
// import step from "../../assets/ta.svg";
export default class Capacity extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Capacity Development Methodology</title>
          <meta
            itemprop="name"
            content="Detailed list of all the services that ECA Consultency Offers"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/img/services/tax.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          id="pricing"
          className="pricing"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div
              className="section-title"
              data-aos="zoom-out"
              // style={{ paddingTop: "%" }}
            >
              <h2>Info</h2>
              <p>Capacity Development Methodology</p>
            </div>

            <div className="row justify-content-center">
              {/* <img
                src={step}
                alt="three steps"
                width="100%"
                height="100%"
                style={{ marginBottom: 0 }}
              /> */}
              <div className="col-lg col-md-6 p-1">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  {/* <h3> */}
                  {/* Planning */}
                  {/* <p className="text-white ">Planning</p> */}
                  {/* </h3> */}

                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left" style={{ fontSize: "16px" }}>
                      E.C.A has experience of providing institutional training
                      and capacity buildings in the field of general accounting,
                      IFRS, financial management, budgeting, taxation, inventory
                      management, IT, regulatory reporting and financial
                      controlling, business development, marketing, E-Governance
                      etc.
                    </li>
                    <li className="text-left" style={{ fontSize: "16px" }}>
                      Our training team consists of professionals with hundreds
                      of hours of past experience of providing training on
                      financial management including budgeting. Our training
                      team focuses on the upgrading knowledge base, changing
                      work behavior and build imperative attitudes of the
                      participants. Various informative and interactive
                      techniques are used to make training more motivating.
                    </li>
                    <li className="text-left" style={{ fontSize: "16px" }}>
                      Our Adult Learning Approach (ALA) has a proven track
                      record of successfully delivering training and capacity
                      buildings to our valued clients. Our approach enables
                      participants to easily conceive and absorb the delivered
                      knowledge and skill and transform it in the result. The
                      trainings are based on the principle of interaction
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer2 /> */}
        {/* <NewFooter /> */}
      </div>
    );
  }
}
