import styled from 'styled-components';

export const ButtonContainer = styled.button`
    text-tranform-capatalize;
    font-size: 1.4rem;
    background: transparent;
    border : 0.05rem solid;
    border-color:"redorange";
    color : ${props => props.cart?"var(--mainYellow)":"var(--lightBlue)"};
    border-radius:0.5rem;
    padding : 0.2rem 0.5rem;
    cursor:pointer;
    margin: 0.2rem 0.5rem 0.2rem 0;
    transiton:all 0.5s ease-in-out;
    &:hover{
        background: ${props => props.cart?"var(--mainYellow)":"var(--lightBlue)"};
        color:var(--mainBlue);
    }
    &focue{
        outline:none;
    }
`