import React, { Component } from "react";
// import {Link} from 'react-router-dom'
// import Navigation2 from "../Navigation2";
// import Footer2 from "../Footer2";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import metaImg from '../../assets/img/portfolio/fullsize/1.jpg'
// import tik from "../../assets/tik.png";
import steps3 from "../../assets/3s.svg";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";
export default class Advisory extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="mt-5">
        <Helmet>
          <title>Advisory</title>
          <meta
            itemprop="name"
            content="Detailed list of all the services that ECA Consultency Offers"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/img/services/tax.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          id="pricing"
          className="pricing"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div
              className="section-title"
              data-aos="zoom-out"
              // style={{ paddingTop: "5%" }}
            >
              <h2>Info</h2>
              <p>Advisory Services Methodology/Approach</p>
            </div>

            <div className="row justify-content-center">
              <img
                src={steps3}
                alt="three steps"
                width="100%"
                height="100%"
                style={{ marginBottom: 0 }}
              />
              <div className="col-lg-4 col-md-6 p-1">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  <h3>
                    Initiate and Plan
                    {/* <p className="text-white ">Planning</p> */}
                  </h3>

                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul style={{ height: "250px" }}>
                    <li className="text-left">• Planning meetings</li>
                    <li className="text-left">
                      • Introduction with organization
                    </li>
                    <li className="text-left">• Team mobilization</li>
                    <li className="text-left">
                      • Establish communication and project protocols{" "}
                    </li>
                    <li className="text-left">
                      • Prepare and submit detail work plan
                    </li>
                    <li className="text-left">• Request initial information</li>
                    <li className="text-left">• Agree communication formats</li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-1 col-md-6">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  <h3>
                    Execute
                    {/* <p className="text-white ">Assessing Risk</p> */}
                  </h3>
                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul style={{ height: "250px" }}>
                    <li className="text-left">• Visit the entity</li>
                    <li className="text-left">
                      • Understanding of policies and procedures
                    </li>
                    <li className="text-left">
                      • Assessments and evaluation of current state in
                      accordance with TORs
                    </li>
                    <li className="text-left">• Testing of transactions</li>
                    <li className="text-left">
                      • Identification of gaps and recommendation of
                      remedial/corrective actions
                    </li>
                    <li className="text-left">
                      • Briefing meetings with organization
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-1 col-md-6 mt-4 mt-md-0">
                <div
                  className="box featured "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  {/* <Link to="/services/advisory"> */}
                  <h3>
                    Reporting & Close out
                    {/* <p className="text-white ">
                      Evaluation/Testing Internal Control
                    </p> */}
                  </h3>
                  {/* </Link> */}

                  {/* <h4><sup>$</sup>19<span> / month</span></h4> */}
                  <ul style={{ height: "250px" }}>
                    <li className="text-left">
                      • Prepare and submit draft review/assessment report
                    </li>
                    <li className="text-left">
                      • Provide briefing to the respective stakeholders from
                      clients
                    </li>
                    <li className="text-left">
                      • Finalization and submission of the review/assessment
                      reports to client
                    </li>
                    <li className="text-left">• Carry out closing meeting</li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer2 /> */}
        {/* <NewFooter /> */}
      </div>
    );
  }
}
