export const Capacity = [
  {
    id: 12,
    name: "Zareefullah Fazli",
    board: "Project Management Expert and Trainer ",
    title: "MBA and PMP",
    details:
      "Mr. Fazli has worked in many organizations such as Swedish Committee of Afghanistan (SCA), Development & Humanitarian Services for Afghanistan (DHSA), American University of Afghanistan (AUAF), Muslim English Language Institute (MELI) and Ministry of Finance. He has experience in Capacity Development, Training Coordination, Communication Management, Human Resources Management and Project Management and worked with different national, international and public sector organizations at various capacities, specifically in capacity development",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/zareef.jpg",
  },
  {
    id: 20,
    name: "Abdul Ahad Naseri",
    board: "Legal",
    title: "BPS and MPS",
    details:
      "Mr. Naseri has worked in many organizations such as Bank Mili Afghanistan and Ministry of Finance in capacity of Legal Expert. He has extensive knowledge of Afghanistan Constitution, Afghanistan Public Financial Management Law, Afghanistan Tax Law, and other relevant laws and regulations affecting the businesses and organizations the national and international organizations.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/male.png",
  },
  {
    id: 40,
    name: "Tariq Roostayee",
    board: "Software Development Expert",
    title: "BCS",
    details:
      "Mr. Roostayee has worked in many organizations such as Swedish Committee of Afghanistan (SCA), Ministry of Communication and Information Technology, Ministry of Agriculture and Ministry of Finance. He has experience in software and system development and implementation. He has developed and implemented more than 25 systems in national, international and public sector organizations.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/staff/tariq.jpg",
  },

  {
    id: 3,
    name: "Shahnaz Mal",
    board: "Human Resource Management Consultant",
    title: "BBA",
    details:
      "Ms. Shahnaz has worked with different organizations at technical level. She has good command on social counselling and able to manage conflicts with effective results. She can handle the change management and reforms at different level of the organization more efficiently and effectively. ",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "/img/female.png",
  },
  // {
  //   id: 154,
  //   name: "Nadia Nadeem",
  //   board: "Partner & President ",
  //   title: "Master in IR, Bachelor in Law and Journalism",
  //   details:
  //     "Ms.Nadia Nadeem is holding one master and two bachelor degrees, providing lectures since 7 years in different private Universities.She works as social media expert in directorate of public relation in Ministry of Mine and Petroleum.",
  //   fb: "facebook.com",
  //   instagram: "instagram.com",
  //   img: "/img/female.png",
  // },
  // {
  //   id: 10,
  //   name: "Hashmatullah Ateed",
  //   board: "Partner & President ",
  //   title: "MBA, CIA",
  //   details:
  //     "Mr. Hashmatullah Ateed is holding M.Phil. & BSc degrees in Economics,Banking & Finance from International Islamic University Of Islamabad. He is a researcher and got some papers published in well reputed international journals. Mr. Ateed has worked with different public and private organizations on the capacity of Audit, Compliance, Internal Control and Finance. Mr. Ateed has worked in different organizations such as OFRD, Pashtany Bank, EXCHANGERZONE Inc. HEDP, MoHE and MOE. His audit experience is in different organizations with special emphasis on compliance With International Financial Reporting Standards, compliance with Banking Regulations and International standards on Auditing. Currently he is working as a Consultant at ECA.",
  //   fb: "facebook.com",
  //   instagram: "instagram.com",
  //   img: "/img/male.png",
  // },
  // {
  //   id: 9,
  //   name: "Ajmal Yaqoobi",
  //   board: "Partner & President ",
  //   title: "MBA, CFE",
  //   details:
  //     "Mr. Ajmal Yaqoubi is a BBA (Bachelor in Business Administration) and currently CFE candidate. Mr. Yaqoubi has got a strong interpersonal and communication skills which allows him to communicate and resolve any problems on time. He is skilled in the fields Of External Audit, Internal Audit, Finance, Procurement and administrative management and Capacity Development. He has worked at different organizations such as Azizi Bank, DAI/SAHAR USAID, MAIL MOF and many other well reputed organizations. Currently he is working as Audit Senior at ECA.",
  //   fb: "facebook.com",
  //   instagram: "instagram.com",
  //   img: "/img/male.png",
  // },
];
