import React from "react";
// import ImageGalleryInside from './ImageGalleryInside'
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// import BackgroundSlideshow from 'react-background-slideshow'
import BackgroundSlider from "react-background-slider";

// import image1 from '../assets/backImages/1.jpg'
// import image2 from '../assets/backImages/2.jpg'
// import image3 from '../assets/backImages/3.jpg'
// import image4 from "../assets/backImages/4.jpg";

// const BackgroundSlider = React.lazy(() => import('react-background-slider'));

import {
  sloVariants,
  // sloganVariants,
  // ButtonVariant,
} from "./Tools/MotionVariants";
// import {AnimatedDiv,AnimatedButton,AnimatedP} from './Tools/FramerMotionLazy'

// import img1 from "../assets/backImages/s4.jpg";
import img2 from "../assets/backImages/s3.jpg";
// import img3 from "../assets/backImages/s7.jpg";
// import img4 from "../assets/backImages/s18.jpg";
// import img5 from "../assets/backImages/s21.jpg";

export default function Masthead() {
  return (
    <header className="masthead">
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <BackgroundSlider images={[img2, img2]} duration={20} transition={1.2} />
      {/* </Suspense> */}

      {/* new center test */}

      <div className="container h-100 ">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <motion.div
            variants={sloVariants}
            initial="hidden"
            animate="visible"
            className="col-lg-12 align-self-end text-left align-items-center justify-content-center text-center"
            style={{ marginBottom: "-12%" }}
          >
            <h1
              className="text-uppercase text-white font-weight-bold mb-1 p-3"
              style={{ fontSize: "3.2rem" }}
            >
              Eagle Wise Chartered Accountants
            </h1>

            <h4
              className="  font-italic text-center  mb-1 "
              style={{
                color: "orangered",
              }}
            >
              One of The Quality Service Providers{" "}
            </h4>
          </motion.div>
          <div className="col-lg-8 align-self-baseline"></div>
        </div>
      </div>

      {/* end */}

      {/* old bottom left */}
      {/* <div className="container-fluid h-100 ">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <motion.div
            variants={sloVariants}
            initial="hidden"
            animate="visible"
            className="col-lg-10 align-self-end text-left "
            style={{ marginLeft: "-11%", marginBottom: "-7%" }}
          >
            <h1
              className="text-uppercase text-white font-weight-bold mb-1 p-3"
              style={{ margin: "0" }}
            >
              Eagle Wise Chartered Accountants
            </h1>
            <h4
              className=" text-white font-italic  float-left mb-1 p-3"
              style={{
                backgroundColor: "orangered",
              }}
            >
              One of The Most Trusted Quality Service Providers{" "}
            </h4>
          </motion.div>
          <div className="col-lg-8 align-self-baseline">
          </div>
        </div>
      </div> */}
    </header>
  );
}
