import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class Career extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Careers</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div>
              <div
                className="section-title"
                data-aos="zoom-out"
                id="ENVIRONMENT"
              >
                {/* <h2>About</h2> */}
                <p
                  className="section-title"
                  style={{ fontWeight: "bold", marginBottom: "-7%" }}
                >
                  Careers
                </p>
              </div>
            </div>

            <ul className="nav nav-tabs row d-flex">
              <p className="fs-18 m-3">
                ECA does not make any compromise in recruiting the professional
                staff. In order to ensure that the right candidate is recruited,
                the induction process is set in a manner which allows only the
                most competent personnel to get selected. The process involves
                test on numeracy and verbal reasoning, interviews with the HR
                department, Managers, also the candidates are required to solve
                a case scenario that checks their ability to interpret a given
                set of data and transforming it into useful and relevant
                information.
              </p>
              <h4 className="ml-3">Professional Staff Development</h4>
              <p className="fs-18 m-3">
                Training to all employees and staff at ECA are carried out in
                the light of the requirements of its International Standards. It
                requires of the staff that they are committed to ensuring that
                our people have the skills and expertise necessary to provide
                the range of quality services and technical excellence required
                by our clients. ECA opens its doors to the fresh graduates and
                accept them at the initial step as interns and promoting them to
                executive positions as able.
              </p>

              <p className="fs-18 m-3">
                If you would like to work with ECA, kindly contact
                info@eca.org.af
              </p>
            </ul>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}>
         
            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
