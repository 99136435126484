import React from "react";
import tsdo from "../../assets/clients/tsdo.jpeg";
import scg from "../../assets/clients/scg.png";
import usaid from "../../assets/clients/usaid.png";
import gbt from "../../assets/clients/gbt.png";
import agt from "../../assets/clients/agt.jpg";
export default function AdviClients() {
  return (
    <section id="clients" class="section-bg">
      <div class="container">
        <div class="section-header">
          <h3>Advisory Clients</h3>
          <p></p>
        </div>
        <div
          class="row no-gutters clients-wrap clearfix wow fadeInUp"
          //   style="visibility: visible; animation-name: fadeInUp;"
        >
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={gbt} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={usaid} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={agt} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={tsdo} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={scg} class="img-fluid" alt="" />{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
