import React, { Component } from "react";
// import {Link} from 'react-router-dom'
// import Navigation2 from "../Navigation2";
// import Footer2 from "../Footer2";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import metaImg from '../../assets/img/portfolio/fullsize/1.jpg'
// import tik from "../../assets/tik.png";
import step from "../../assets/ta.svg";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";
export default class Taxation extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="mt-5">
        <Helmet>
          <title>Taxation Methodology</title>
          <meta
            itemprop="name"
            content="Detailed list of all the services that ECA Consultency Offers"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/img/services/tax.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          id="pricing"
          className="pricing"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Info</h2>
              <p>Taxation Methodology</p>
            </div>

            <div className="row justify-content-center">
              <img
                src={step}
                alt="three steps"
                width="100%"
                height="100%"
                style={{ marginBottom: 0 }}
              />
              <div className="col-lg-4 col-md-6 p-1">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  <h3>
                    Planning
                    {/* <p className="text-white ">Planning</p> */}
                  </h3>

                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul style={{ height: "200px" }}>
                    <li className="text-left">• Planning meetings</li>
                    <li className="text-left">
                      • Orientation/Kick-off meeting
                    </li>
                    <li className="text-left">
                      • Identify requirements and perform the related planning
                    </li>
                    <li className="text-left">
                      • Obtain understanding of the current state
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-1 col-md-6">
                <div className="box featured" data-aos="zoom-in">
                  {/* <Link to="services/audit-assurance"> */}
                  <h3>
                    Execution
                    {/* <p className="text-white ">Assessing Risk</p> */}
                  </h3>
                  {/* </Link> */}
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul style={{ height: "200px" }}>
                    <li className="text-left">• Data Collection</li>
                    <li className="text-left">
                      • Reviewing the transactions, accounting records,
                      financial reports and tax documents
                    </li>
                    <li className="text-left">
                      • Analyze the accounting records and conduct meeting with
                      relevant authorities
                    </li>
                    <li className="text-left">
                      • Perform the assessment procedure
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 p-1 col-md-6 mt-4 mt-md-0">
                <div
                  className="box featured "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  {/* <Link to="/services/advisory"> */}
                  <h3>
                    Conclusion
                    {/* <p className="text-white ">
                      Evaluation/Testing Internal Control
                    </p> */}
                  </h3>
                  {/* </Link> */}

                  {/* <h4><sup>$</sup>19<span> / month</span></h4> */}
                  <ul style={{ height: "200px" }}>
                    <li className="text-left">• Exchange closing letters</li>
                    <li className="text-left">
                      • Determine the tax payable/tax payment amount
                    </li>
                    <li className="text-left">
                      • Collection of tax clearance documents from tax
                      department.
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer2 /> */}
        {/* <NewFooter /> */}
      </div>
    );
  }
}
