import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";
import { Circle } from "react-feather";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class EcaAtGlance extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>ECA at a Glance</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div
              className="row content"
              // style={{ marginBottom: "-7%" }}
              data-aos="fade-up"
            >
              {/* Start of First Column */}
              <div className="col-lg-6">
                <div className="section-title" data-aos="zoom-out">
                  <h2>About Us</h2>
                  <p>ECA at a Glance</p>
                </div>
                <p className="fs-18 mb-3">
                  ECA is Afghanistan based company formally registered in 2014
                  with Ministry of Commerce, Afghanistan under license number
                  D-66649. The company is privately owned and governed by a
                  group of Afghans, having extensive experience, professional
                  skills and qualifications. Most of the ECA team holds
                  Professional qualification comprised of ACCA, CA, CPA, CIA,
                  CFE, and CISA additional to higher academic qualifications,
                  which are required to achieve the objectives of the ECA.
                  <br /> The scope of ECA services include Auditing, Taxation
                  and Advisory subdivided into, Internal and External Audits &
                  Assurance, Compliance and Performance Auditing, Forensic
                  Auditing, Review Engagements Accounting and Financial
                  Management, Taxation Advisory & Compliances, and Management
                  Assessment, Business Planning, Capacity Development, Research
                  & Survey and Legal & Corporate Services.
                </p>
              </div>

              {/* end of first column */}
              <div className="col-lg-6 pt-4 pt-lg-0">
                {/* The Second Column */}
                <div className="section-title" data-aos="zoom-out">
                  {/* <h2></h2> */}

                  <p style={{ marginTop: "16px" }}>ECA Scope of Services</p>
                </div>

                <p className="fs-18 mb-3">
                  ECA has a large market to target in regards of sector and
                  industry wise. We categorize market into below six classes:
                </p>

                <p className="fs-18 mb-3 ml-3">
                  <Circle color="orangered" size={11} className="mr-3" />
                  Individuals and Investors
                  <br />
                  <Circle color="orangered" size={11} className="mr-3" />
                  Small Businesses - Defined as businesses with 1 to 24
                  employees, this is the second largest and fastest growing
                  segment in our region.
                  <br />
                  <Circle color="orangered" size={11} className="mr-3" />
                  Medium Businesses - 25 to 499 employees.
                  <br />
                  <Circle color="orangered" size={11} className="mr-3" />
                  Large Businesses - 500 or more employees.
                  <br />
                  <Circle color="orangered" size={11} className="mr-3" />
                  Authorities and Public Organizations
                  <br />
                  <Circle color="orangered" size={11} className="mr-3" />
                  National & International NGOs
                </p>

                {/* the Second Column */}
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}>
         
            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
