import React, { Component } from "react";
// import {Link} from 'react-router-dom'
// import Navigation2 from "../Navigation2";
// import Footer2 from "../Footer2";
import "./allservices.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Circle } from "react-feather";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";
// import metaImg from '../../assets/img/portfolio/fullsize/1.jpg'
// import tik from "../../assets/tik.png";
export default class AllServices extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="mt-5">
        <Helmet>
          <title>ECA Services</title>
          <meta
            itemprop="name"
            content="Detailed list of all the services that ECA Consultency Offers"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/img/services/tax.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          id="pricing"
          className="pricing"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Info</h2>
              <p>Our Services List</p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="box featured" data-aos="zoom-in">
                  <Link to="services/audit-assurance">
                    <h3>Audit & Assurance</h3>
                  </Link>
                  {/* <h4><sup>$</sup>0<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      External Audit
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Internal Audit & Reviews
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Compliance Audit
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Performance Audit
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Operational Audit
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Statutory Audit
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Review Engagements
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      IT Audit
                    </li>

                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Due Diligence
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Agreed upon procedures
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Assurance on non-financial information
                    </li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-4 mt-md-0 mb-2">
                <div
                  className="box featured "
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <Link to="/services/advisory">
                    <h3>Advisory</h3>
                  </Link>

                  {/* <h4><sup>$</sup>19<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Risk Advisory & Crises Management
                    </li>

                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Accounting and Book Keeping
                    </li>
                    {/* <li>
                      <Circle color="orangered" size={11} className="mr-2" />
                      Accounting and Book Keeping
                    </li> */}
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Financial Planning
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Budgeting, forecasting and business planning
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Management accounting
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Business Consultancy
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Human Resources
                    </li>
                    {/* <li>Software Develpment</li> */}
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Litigation Support
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Business Valuation
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Payroll Management
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Standards for Not-For-Profit Organizations (ASNPO)
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Company secretarial services including company formation &
                      promotion
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <Link to="/services/taxation">
                    <h3>Taxation</h3>
                  </Link>

                  {/* <h4><sup>$</sup>29<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Corporate Tax Compliance
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Employment Tax
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Income Tax
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Value Added Tax-VAT
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Property Tax
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      International/Double Taxation
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Tax Controversy and Dispute Resolution
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Tax Advisory
                    </li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mb-4">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <Link to="/services/capacity-development">
                    <h3>Capacity Development</h3>
                  </Link>

                  {/* <span className="advanced">Advanced</span> */}
                  {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Financial Management
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Accounting and Auditing
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Business Management
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Human Resource
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Strategy Management
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Office Management
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Quality Management System
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Planning and Budgeting
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Business Communication
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Risk Management
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Internal Controls & Audit
                    </li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mb-4">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <Link to="/services/legal-services">
                    <h3>Legal & Corporate</h3>
                  </Link>

                  {/* <span className="advanced">Advanced</span> */}
                  {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Consultation for legal information and advice
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Consultation to review documents (such as agreements,
                      leases, wills, corporation documents, do-it-yourself court
                      forms)
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Mediation, arbitration, or other third party services.
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Preparation of litigation documents
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Obtaining business license for national and international
                      orgs
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Emigration services as obtaining entry Visa and Work
                      Permit
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Assistance the client with dissolution procedures
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Recruitment support to national and international orgs
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Procurement support to national and international orgs
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Facilities Management support to national and
                      international orgs
                    </li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              {/* 1 */}
              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mb-4">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <Link to="/services/research-survey">
                    <h3>Research & Survey</h3>
                  </Link>

                  {/* <span className="advanced">Advanced</span> */}
                  {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Qualitative Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Quantitative Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Theoretical Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Applied Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Exploratory Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Descriptive Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Casual and Fundamental Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Experimental Research
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Online Surveys
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Paper Surveys
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Cross-Sectional Studies
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Longitudinal Surveys
                    </li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              {/* 1 */}
              {/* 1 */}
              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mb-4">
                <div
                  className="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <Link to="/services/research-survey">
                    <h3>Asset Management</h3>
                  </Link>

                  {/* <span className="advanced">Advanced</span> */}
                  {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                  <ul>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Cash Management Service
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Inventory Planning & controlling
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Replenishment Services
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Material Optimization
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Inventory Cost Accounting
                    </li>
                    <li className="text-left">
                      <Circle color="orangered" size={11} className="mr-2" />
                      Warehouse Operations Optimization
                    </li>

                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                    <li className="text-left"></li>
                  </ul>
                  <div className="btn-wrap">
                    {/* <a href="#hello" className="btn-buy">Buy Now</a> */}
                  </div>
                </div>
              </div>
              {/* 1 */}
            </div>
          </div>
        </section>

        {/* <Footer2 /> */}
        {/* <NewFooter /> */}
      </div>
    );
  }
}
