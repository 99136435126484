// export const TeamData = [

//     {
//         id:1,
//         name:"Fazal Subhan",
//         title:"CAF (Certified in Accounting and Finance)",
//         details:"Mr.Fazal is a Meticulous, bright, young finance professional with 8 years of experience having worked for Deloitte Pakistan one of the big-4 professional services firms along with an added advantage Of aviation industry exposure Of 1 year and 8 months as Chief Accountant A highly successful Accountant and Auditor with valuable experience in external audit, internal audit, financial reporting, taxation, auditing and cost accounting covering a variety of industries from start-up business to established corporates.",
//         fb:"facebook.com",
//         instagram:"instagram.com",
//         img: 'img/male.png'
//     },
//     {
//         id:2,
//         name:"Sayed Haris",
//         title:"ACCA BSC (Hon)-UK",
//         details:"Sayed Haris is a Chartered Accountant (ACC-A), and has got great knowledge and experience in auditing and risk advisory services. He has worked at different organizations, such as Ernst & Young (EY) and Ministry of Finance, Afghanistan. He has performed various assignments in the past such, external audits, micro assessments, capacity assessments, fiduciary review, compliance audit, special audits, spot checks, and etc. Currently, he is working as Senior Consultant at ECA.",
//         fb:"facebook.com",
//         instagram:"instagram.com",
//         img: 'img/female.png'
//     },

// ];

// Ms.Sahak is Chartered Accountant and has alot of experience in auditing, financial management, training and teaching. Mr.Sahak has worked at different position at both international and national organiztions. His professional
// Ms.Nadia is holding one master and two Bachelor degrees, Providing lectrures since 7 Years in different Private Universitiers. She works as Social Media Expert in derectorate of publice relation in ministry of Mine and Petroleum.
// He is a Chartered Accountant(ACCA) and has got great knowledge and experience in auditing and risk advisory services. He has worked at different organiztions, such as Ernst&Young (EY) and ministry of finance Afghanistan.
// Mr.Fazal is a Meticulous, Bright and young finance professional with 8 years of experience having worked for Deloitte Pakistan one of the big-4 professional services firms with an added advantage of aviation industry exposure of 1 year and 8 months as Chief Accountant.

export const TeamData = [
  {
    id: 6,
    name: "Nauroz Khan",
    title: "BBA,MPhill Finance, MCP",
    details:
      "Nauroz Khan has experience and got valuable knowledge since ten (10) years in field of business development, business management, lectureship, capacity building with different private local and international, governmental, national and international NGOs.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/nauroz.jpg",
  },
  {
    id: 8,
    name: "Foulad Khan Shinwary",
    title: "Master",
    details:
      "Foulad Khan Shenwari has been working with the Afghan Government and NGOs on budget preparation for support unit (Finance, HR, Procurement and Administration) compared actual results with budgeted targets and defined different control mechanism. Conducted Internal and External Audit for UNDP and Government Projects, and beside that he is experienced with different private sector organization. Currently is working as Senior Consultant at ECA",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/foulad.jpg",
  },
  {
    id: 14,
    name: "Rahmatullah",
    title: "Bachelor in statistics",
    details:
      "Rahmatullah is holding degree in BS Statistics from university of Peshawar and has extensive knowledge in sampling both qualitative and quantitative data analysis. He has worked as data analyst in jubilee insurance company, Peshawar, Pakistan and has worked with SILKRAM as tax consultant.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/rahmat_u.jpeg",
  },

  {
    id: 1,
    name: "Fazal Subhan",
    title: "CAF (Certified in Accounting and Finance)",
    details:
      "Mr.Fazal is a Meticulous, bright, young finance professional with 8 years of experience having worked for Deloitte Pakistan, one of the big-4 professional services firms along with an added advantage Of aviation industry exposure Of 1 year and 8 months as Chief Accountant. A highly successful Accountant and Auditor with valuable experience in external audit, internal audit, financial reporting, taxation, auditing and cost accounting covering a variety of industries from start-up business to established corporates.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },
  {
    id: 2,
    name: "Sayed Haris",
    title: "ACCA BSC (Hon)-UK",
    details:
      "Sayed Haris is a Chartered Accountant (ACC-A), and has got great knowledge and experience in auditing and risk advisory services. He has worked at different organizations, such as Ernst & Young (EY) and Ministry of Finance, Afghanistan. He has performed various assignments in the past such as, external audits, micro assessments, capacity assessments, fiduciary review, compliance audit, special audits, spot checks, and etc. Currently, he is working as Senior Consultant at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 3,
    name: "Nadia Nadeem",
    title: "Master in IR, Bachelor in Law and Journalism",
    details:
      "Ms.Nadia Nadeem is holding one master and two bachelor degrees, providing lectures since 7 years in different private Universities.She works as social media expert in directorate of public relation in Ministry of Mine and Petroleum.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/female.png",
  },

  {
    id: 4,
    name: "Hayatullah Sahak",
    title: "ACCA, BSC (Hon)-UK",
    details:
      "Mr. Sahak is Chartered Accountant (ACC-A), and has got large experience of auditing, financial management, training and teaching. Mr. Sahak has worked at different positions at both international and national organizations. His professional experience includes, auditing, financial management, and teaching. Currently, he is working as Senior Consultant at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 5,
    name: "Anwar Shinwari",
    title: "MBA, CIA",
    details:
      "Mr. Shinwari is MBA and CIA, and has large experience in internal auditing, training, capacity building and advisory services. He has worked at different organization in Afghanistan at different positions. He possesses well experience knowledge Of Afghanistan government processes. Further, Mr. Shinwari has good knowledge Of audit methodology, risk assessment and report writing. Currently, he is working as Senior Consultant at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 7,
    name: "Najeebullah Azizi",
    title: "PHD in Finance",
    details:
      "Dr.Najeebullah Azizi is qualified in the field Of finance and he has 6 years' experience in lectureship and 4 years' experience in training with different national and international organization as a master trainer.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 9,
    name: "Ajaml Yaqoobi",
    title: "MBA, CFE",
    details:
      "Mr. Ajmal Yaqoubi is a BBA (Bachelor in Business Administration) and currently CFE candidate. Mr. Yaqoubi has got a strong interpersonal and communication skills which allows him to communicate and resolve any problems on time. He is skilled in the fields Of External Audit, Internal Audit, Finance, Procurement and administrative management and Capacity Development. He has worked at different organizations such as Azizi Bank, DAI/SAHAR USAID, MAIL MOF and many other well reputed organizations. Currently he is working as Audit Senior at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },
  {
    id: 10,
    name: "Hashmatullah Ateed",
    title: "MBA, CIA",
    details:
      "Mr. Hashmatullah Ateed is holding M.Phil. & BSc degrees in Economics,Banking & Finance from International Islamic University Of Islamabad. He is a researcher and got some papers published in well reputed international journals. Mr. Ateed has worked with different public and private organizations on the capacity of Audit, Compliance, Internal Control and Finance. Mr. Ateed has worked in different organizations such as OFRD, Pashtany Bank, EXCHANGERZONE Inc. HEDP, MoHE and MOE. His audit experience is in different organizations with special emphasis on compliance With International Financial Reporting Standards, compliance with Banking Regulations and International standards on Auditing. Currently he is working as a Consultant at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },
  {
    id: 11,
    name: "Zia Ur Rahman",
    title: "ACCA,CISA,BA",
    details:
      "Mr. Zia Ur Rahman is ACCA, CISA and B.A Economics, and has got extensive knowledge Of external auditing, internal auditing and advisory services. He has worked with well-known organizations, such as Grant Thornton (GT), Ernst & Young (EY) and Ministry Of Finance in different capacities. Mr. Zia has completed multiple assignments and projects in the past. He has got good control over external auditing, micro assessments, internal auditing and financial management in banking sectors, power sector, and NGO sector. Currently, he is working as audit supervisor at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 12,
    name: "Zareefullah Fazli",
    title: "MBA, PMP",
    details:
      "Mr. Zareefullah Fazli is a PMP Certified and MBA. Mr. Fazli has got huge experience in Capacity Development, Training Coordination, Communication Management, Human Resources Management and Project Management and worked with different national, international and public sector organizations at various capacities, specifically in capacity development. He has worked in different organizations such as Swedish Committee of Afghanistan (SCA), Development & Humanitarian Services for Afghanistan (DHSA), American University of Afghanistan (AUAF), Muslim English Language Institute (MELD, Ministry of Finance and others. Mr. Fazli is currently working with ECA as Capacity Development Advisor.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 13,
    name: "Aimal Bahawi",
    title: "BBA,MBA",
    details:
      "Aimal Bahawi is MBA finance and BBA. He has got vast experience in auditing & assurance, controlling, accounting & compilation, financial management, accounting and internal control system review and compliance audits, Budget estimates & plans, financial action plans, financial reporting annually, quarterly & monthly. Mr. Bahawi has worked in different organizations such as Eshan group of companies, Ministry of Rural Rehabilitation and Development (MRRD) with various donors like UNICEF, WB, Spanish, Norwegian, UNDP and government. He has performed various assignments & projects in the past with UNDP, UNICEF, USAID, WB, ministries and other commercial entities. Currently, he is working as a Consultant at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 15,
    name: "Sayed Yawar Sadat",
    title: "BBA",
    details:
      "Mr. Sayed Yawar is a BBA qualified and has got extensive knowledge of external auditing, internal auditing, Taxation and advisory services. He has worked with well-known organizations, such as MG' llyas Saeed Chartered Accountants (MGI-ISCA) and Rana University Of Higher Education since 2014. Mr. Yawar has completed multiple assignments and projects in the past. He has got good control over external auditing, Taxation, internal auditing and financial management in Financial Institutions sectors, power sector, and NGO sector. Currently, He is working as an Auditor at ECA",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/yawar.jpg",
  },

  {
    id: 16,
    name: "Zahidullah",
    title: "M.Com",
    details:
      "Mr. Zahidullah is M.Com from University Of Peshawar, and has got extensive knowledge Of Management, administration, internal control and advisory services. He has worked with different organizations, such as Aria Group of Companies, Organization for Research and Community Development (ORCD), Sky travel and tours, Mashal University, Professional Institute of Business and Finance (PIBF). Mr. Zahid has completed multiple assignments and projects in the past. He has got good control over micro assessments, internal controls and financial management in Private sectors, and NGO sector. Currently, he is working as a Senior Consultant at ECA.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/male.png",
  },

  {
    id: 17,
    name: "Soma Sakhra",
    title: "Bachelor in Civil Engineering",
    details:
      "Ms.Soma has worked with Mashal University as receptionist and has strong skills in communication and handling organizational records or filing. She is also very good in handling and organizing events and meetings.",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/female.png",
  },

  {
    id: 18,
    name: "Abdul Muneer Muhammadi",
    title: "Senior Supervisor – Audit & Assurance",
    details: "Advanced Diploma in Accounting and Business from ACCA, UK",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/muneer.jpeg",
  },

  {
    id: 19,
    name: "Rahmat Karimi",
    title: "Supervisor/Consultant",
    details: "ACCA Finalist",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/rahmat_k.jpeg",
  },
  {
    id: 20,
    name: "Ziarat Gul",
    title: "Tax Officer",
    details: "BBA",
    fb: "facebook.com",
    instagram: "instagram.com",
    img: "img/staff/ziarat.jpeg",
  },
];

// Ms.Sahak is Chartered Accountant and has alot of experience in auditing, financial management, training and teaching. Mr.Sahak has worked at different position at both international and national organiztions. His professional
// Ms.Nadia is holding one master and two Bachelor degrees, Providing lectrures since 7 Years in different Private Universitiers. She works as Social Media Expert in derectorate of publice relation in ministry of Mine and Petroleum.
// He is a Chartered Accountant(ACCA) and has got great knowledge and experience in auditing and risk advisory services. He has worked at different organiztions, such as Ernst&Young (EY) and ministry of finance Afghanistan.
// Mr.Fazal is a Meticulous, Bright and young finance professional with 8 years of experience having worked for Deloitte Pakistan one of the big-4 professional services firms with an added advantage of aviation industry exposure of 1 year and 8 months as Chief Accountant.
