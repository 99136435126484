export const sloVariants = {
    hidden:{
       opacity:0
    },
    visible:{
        opacity:1,
        transition:{delay:1,duration:1}
    }
}

export const sloganVariants = {
    hidden:{
       opacity:0
    },
    visible:{
        opacity:1,
        transition:{delay:2,duration:1}
    }
}

export const ButtonVariant = {
    hidden:{
       opacity:0
    },
    visible:{
        opacity:1,
        x:[0,-20,20,-20,20,0],
        transition:{delay:1}
    },
    hover:{
        scale:[1,1.1,1,1.1,1],
        textShadow:"0px 0px 8px rgb(255,255,255)",
        boxShadow:"0px 0px 8px rgb(255,255,255)"
    }
}

export const hVariants = {
    hidden:{
        opacity:0
     },
     visible:{
         opacity:1,
         transition:{delay:0.1,duration:1}
     }
}

export const imgVariants = {
    visible:{
        scale:0.9,
        transition:{duration:0.4}
    }
}