import React from "react";
import aci from "../../assets/clients/aci.png";
import pax from "../../assets/clients/pax.jpg";
import eve from "../../assets/clients/eve.png";
import mashal from "../../assets/clients/mashal.jpg";
import brac from "../../assets/clients/brac.png";
import aah from "../../assets/clients/aah.png";
import sabatv from "../../assets/clients/sabatv.jpg";
import gs from "../../assets/clients/gs.jpeg";
import imc2 from "../../assets/clients/imc2.jpg";
import agt from "../../assets/clients/agt.jpg";
// import scg from "../../assets/clients/scg.png";
export default function TaxationClients() {
  return (
    <section id="clients" class="section-bg">
      <div class="container">
        <div class="section-header">
          <h3>Taxation Clients</h3>
          <p></p>
        </div>
        <div
          class="row no-gutters clients-wrap clearfix wow fadeInUp"
          //   style="visibility: visible; animation-name: fadeInUp;"
        >
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>Child Fund Afghanistan</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>IDG Security (Afghanistan)</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={aci} class="img-fluid" alt="" />{" "}
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={pax} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={eve} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={mashal} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={brac} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>Ariana Financial Services</h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>Mufad Microfinance</h2>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={sabatv} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>IBDF International </h2>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>Adil Faizi Construction </h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={aah} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>ROWCO Consulting Corporation </h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>ROWCO Security Services </h2>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={gs} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={imc2} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={agt} class="img-fluid" alt="" />{" "}
            </div>
          </div>
          {/* <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src={scg} class="img-fluid" alt="" />{" "}
            </div>
          </div> */}
          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <h2>Sicuro logistic services</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
