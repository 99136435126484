import React, { Component } from "react";
// import "../../css/team.css";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { BOD } from "../../data/BOD_Data.js";
import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
import { sloVariants, hVariants } from "../../components/Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2 } from "../Tools/FramerMotionLazy";
import {
  AnimatedDiv,
  AnimatedH2,
} from "../../components/Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class BoardOfDirectors extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Senior Management</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section"
          id="team"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="text-center mb-4">
              <AnimatedH2
                variants={hVariants}
                initial="hidden"
                animate="visible"
                className="section-heading text-uppercase"
              >
                Senior Management
              </AnimatedH2>
              {/* <AnimatedH3
                variants={hVariants}
                initial="hidden"
                animate="visible"
                className="section-subheading text-muted "
              >
                Our team consists of Amazing and talented youth
              </AnimatedH3> */}
            </div>
            <div className="row mt-4">
              {BOD.map((data) => {
                const linkName = this.state.readMore
                  ? "Read Less << "
                  : "Read More >> ";

                return (
                  <AnimatedDiv
                    key={data.id}
                    variants={sloVariants}
                    initial="hidden"
                    animate="visible"
                    className="col-lg-4 "
                  >
                    <div className="team-member">
                      <img
                        className="mx-auto rounded-circle"
                        src={data.img}
                        alt={data.title}
                      />
                      <h4>{data.name}</h4>
                      <h6 style={{ color: "blue" }}>{data.board}</h6>
                      <p className="text-muted">{data.title}</p>
                      {this.state.readMore
                        ? data.details
                        : `${data.details.substring(0, 150)}...`}
                      <br />
                      <button
                        onClick={() => {
                          this.setState({ readMore: !this.state.readMore });
                        }}
                      >
                        <p style={{ color: "orangered", marginBottom: "10%" }}>
                          {linkName}
                        </p>
                      </button>
                      <br />
                      {/* <p className="text-muted">{data.details}</p> */}
                      {/* <a
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-twitter"></i>
                      </a> */}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-dark btn-social mx-2"
                        href={data.fb}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-dark btn-social mx-2"
                        href={data.instagram}
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </AnimatedDiv>
                );
              })}
            </div>
            {/* <div className="row " style={{ marginBottom: 40 }}>
              <div className="col-lg-8 mx-auto text-center">
                <p className="large  ">
                  All of our members are professionally trained acountants and
                  Economists with several years of practical experience in the
                  field and with legal work permit from the ministry of
                  economics of Afghanistan.{" "}
                </p>
              </div>
            </div> */}
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}>
      
            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
