import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";

// import ContactPage from './Contact_page'
import ProjectInfoPage from "./pages/ProjectInfo/projectInfoPage";
import Default from "./pages/Default";

// import About2 from "./components/About2";

import TeamPage from "./pages/TeamPage";
import AllServices from "./pages/NewCom/AllServices";
// import Index from "./components/SingleServices/Index";
import Audit from "./pages/ServicePages/Audit";
import Advisory from "./pages/ServicePages/Advisory";
import Taxation from "./pages/ServicePages/Taxation";
import Capacity from "./pages/ServicePages/Capacity";
import AssetManagement from "./pages/ServicePages/AssetManagement";
// const TeamPage = lazy(() => import('./components/TeamPage'));
// const AllServices = lazy(() => import('./components/NewCom/AllServices'));
// const Default = lazy(() => import('./components/Default'));

import "./css/fonts/font-awesome-4.7.0/css/font-awesome.css";
import "./css/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import BoardOfDirectors from "./pages/NewTeam/BoardOfDirectors";
import DepartmentWise from "./pages/NewTeam/DepartmentWise";
// import ProfessionalDep from "./components/NewTeam/ProfessionalDep";
import ClientPage from "./pages/Clients/ClientPage";
import EcaAtGlance from "./pages/AboutUs/EcaAtGlance";
import OurValues from "./pages/AboutUs/OurValues";
import MissionVission from "./pages/AboutUs/MissionVision";
import Privacy from "./pages/AboutUs/Privacy";

import "./css/styles.css";
import Aos from "aos";
import "aos/dist/aos.css";
import SocialActivity from "./pages/OtherPages/SocialActivity";
import BtoEnv from "./pages/OtherPages/BtoEnv";
import Confident from "./pages/OtherPages/Confident";
import SocioCultural from "./pages/OtherPages/SocioCultural";
import ContactPage from "./pages/ContactPage/ContactPage";

import LegalCorporate from "./pages/ServicePages/LegalCorporate";
import ResearchSurvey from "./pages/ServicePages/ResearchSurvey";
import Public from "./pages/Sectors/Public";
import Private from "./pages/Sectors/Private";
import NGO from "./pages/Sectors/NGO";
import Career from "./pages/Careers/Career";

// test

import Navigation from "./components/HoverNav/Navigation";
import NewFooter from "./components/HoverNav/NewFooter";
import AuditClients from "./pages/Clients/AuditClients";
import AdviClients from "./pages/Clients/AdviClients";
import TaxationClients from "./pages/Clients/TaxationClients";
import OverView from "./pages/Sectors/OverView";

function App() {
  useEffect(() => {
    Aos.init({ duration: 2000, mirror: true });
  }, []);
  return (
    <>
      <Navigation />
      <Switch>
        <Route exact path="/" component={MainPage}></Route>
        {/* <Route exact path="/contact" component={About2}></Route> */}
        <Route exact path="/contact" component={ContactPage}></Route>
        <Route exact path="/projects/:id" component={ProjectInfoPage}></Route>
        <Route exact path="/team" component={TeamPage}></Route>
        <Route exact path="/services/" component={AllServices}></Route>
        <Route exact path="/services/audit-assurance" component={Audit}></Route>
        <Route exact path="/services/advisory" component={Advisory}></Route>
        <Route exact path="/services/taxation" component={Taxation}></Route>

        {/* Sectors */}

        <Route exact path="/sectors" component={OverView}></Route>
        <Route exact path="/sectors/public" component={Public}></Route>
        <Route exact path="/sectors/private" component={Private}></Route>
        <Route exact path="/sectors/ngo" component={NGO}></Route>

        {/* Sectors */}

        {/* Career */}
        <Route exact path="/career" component={Career}></Route>

        <Route
          exact
          path="/services/legal-services"
          component={LegalCorporate}
        ></Route>
        <Route
          exact
          path="/services/research-survey"
          component={ResearchSurvey}
        ></Route>
        <Route exact path="/clients" component={ClientPage}></Route>

        {/* other pages */}

        <Route exact path="/social-activity" component={SocialActivity}></Route>
        <Route exact path="/b-to-env" component={BtoEnv}></Route>
        <Route exact path="/conf" component={Confident}></Route>
        <Route exact path="/socio-cultural" component={SocioCultural}></Route>

        {/* end of other pages */}

        {/* Clients */}
        <Route exact path="/clients/audit" component={AuditClients}></Route>
        <Route exact path="/clients/key-clients" component={ClientPage}></Route>
        <Route exact path="/clients/audit" component={AuditClients}></Route>
        <Route exact path="/clients/advisory" component={AdviClients}></Route>
        <Route
          exact
          path="/clients/taxation"
          component={TaxationClients}
        ></Route>

        {/* end of clients */}

        {/* about */}
        <Route
          exact
          path="/about/eca-at-a-glance"
          component={EcaAtGlance}
        ></Route>
        <Route exact path="/about/our-values" component={OurValues}></Route>
        <Route
          exact
          path="/about/mission-and-vision"
          component={MissionVission}
        ></Route>
        <Route exact path="/about/privacy-policy" component={Privacy}></Route>
        {/* end about */}
        <Route
          exact
          path="/team/professional-experts"
          component={DepartmentWise}
        ></Route>
        <Route
          exact
          path="/team/board-of-director"
          component={BoardOfDirectors}
        ></Route>
        <Route
          exact
          path="/services/capacity-development"
          component={Capacity}
        ></Route>
        <Route
          exact
          path="/services/asset-management"
          component={AssetManagement}
        ></Route>
        {/* <Route exact path="/services/:name" component={Index}></Route> */}
        <Route component={Default}></Route>
      </Switch>
      <NewFooter />
    </>
  );
}

export default App;
