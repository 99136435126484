import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";
import { Circle } from "react-feather";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class LegalCorporate extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Legal & Corporate Services</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div>
              <div
                className="section-title"
                data-aos="zoom-out"
                // style={{ marginTop: "7%" }}
                id="ENVIRONMENT"
              >
                {/* <h2>About</h2> */}
                <p
                  className="section-title"
                  style={{ fontWeight: "bold", marginBottom: "-6%" }}
                >
                  Legal & Corporate Services
                </p>
              </div>
            </div>

            <ul className="nav nav-tabs row d-flex">
              <p className="fs-18 mx-3">
                The Eagle-Wise Chartered Accountants offers customized and
                outlay filing and registration assistance, due diligence
                inquiries, and compliance with normal corporate and commercial
                chores to businesses and other organizations on a timely basis.
              </p>
            </ul>
            <ul className="mt-3" data-aos="fade-up">
              <li
                style={{ marginLeft: "5%", fontWeight: "bold" }}
                className="d-flex mb-2 align-items-center"
              >
                {/* <Circle color="orangered" size={11} className="mr-2" /> */}
                <p className="fs-16">ECA helps you:</p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle
                  color="orangered"
                  size={11}
                  className="mr-2 align-items-center"
                />
                <p className="fs-16">Put the chosen solution into action</p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle
                  color="orangered"
                  size={11}
                  className="mr-2 align-items-center"
                />
                <p className="fs-16">Administer and control the structure</p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Be sure you're following all local and international laws and
                  regulations
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">Change the structure as needed</p>
              </li>

              {/*  */}
              <li
                style={{
                  marginLeft: "5%",
                  fontWeight: "bold",
                }}
                className="d-flex mb-2 "
              >
                {/* <Circle color="orangered" size={11} className="mr-2" /> */}
                <p className="fs-16">
                  Legal & Corporate Services Offered by ECA :{" "}
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Consultation for legal information and advice.
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Consultation to review documents (such as agreements, leases,
                  wills, corporation documents, do-it-yourself court forms)
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Mediation, arbitration, or other third party services.
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">Preparation of litigation documents</p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Obtaining business license for national and international orgs
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Emigration services as obtaining entry Visa and Work Permit
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Assistance the client with dissolution procedures
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Recruitment support to national and international orgs
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Procurement support to national and international orgs
                </p>
              </li>
              <li
                style={{ marginLeft: "7%" }}
                className="d-flex mb-2 align-items-center"
              >
                <Circle color="orangered" size={11} className="mr-2" />
                <p className="fs-16">
                  Facilities Management support to national and international
                  orgs
                </p>
              </li>
            </ul>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
