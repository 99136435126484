import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";
import socio from "../../assets/img/socio.png";
// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class SocioCultural extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Confidentiality</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div class="row no-gutters">
              <div
                class="col-md-5 order-lg-2 text-white showcase-img"
                // style={{
                //   backgroundImage: `url(../../assets/img/bg-masthead.jpg)`,
                // }}
              >
                <img
                  src={socio}
                  alt="adv"
                  className="img-fluid"
                  data-aos="zoom-in"
                />
              </div>
              <div
                class="col-md-7 order-lg-1 my-auto showcase-text"
                id="Socio-Cultural"
              >
                <h2>Socio-Cultural Forces</h2>
                <p class="lead mb-0">
                  Afghanistan is a country with various ethnicities and races
                  living together. Socio-cultural factors influence a company's
                  internal judgment method in addition to its experiences with
                  the marketplace and its clients. Social behavior, values,
                  literacy, legal structure, and political philosophy are
                  examples of sociocultural influences. The ECA will not change
                  its stated mission or principles depending on the preferences
                  of its intended audience. If entities are supporting business
                  and community growth in the country instead of terrorism, ECA
                  will happily work with them by providing their own services.
                  Moreover, ECA will operate its services with individuals and
                  firms of various nationalities, religions, political
                  affiliations, and several other factors.
                </p>
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
