import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class BtoEnv extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>ECA Benefit to Environment</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section "
          id="team"
          style={{ backgroundColor: "#e6f2f3", marginBottom: "%" }}
        >
          <div className="container mb-5">
            <div>
              <div
                className="section-title"
                data-aos="zoom-out"
                style={{ marginTop: "9%" }}
                id="ENVIRONMENT"
              >
                {/* <h2>About</h2> */}
                <p className="section-title" style={{ fontWeight: "bold" }}>
                  ECA’s Benefit to Environment
                </p>
              </div>
            </div>

            <ul className="nav nav-tabs row d-flex">
              <p className="fs-18 m-3">
                The environment has a significant impact on human health and
                well-being. It is significant since it is humans' only home,
                providing oxygen, food, and other necessities. The well-being of
                all environmental elements is critical to humanity's overall
                life support system. Every day, new technologies have made human
                life more comfortable. These actions, on the other hand, result
                in many types of pollution, rendering the environment dangerous
                and unsuited for living beings to remain healthy. Therefore, ECA
                has taken certain steps to contribute to the fight against
                global warming and to maintain a green environment.
              </p>
            </ul>
            <ul className="mt-3" data-aos="fade-up">
              <li
                style={{ marginLeft: "5%" }}
                className="d-flex mb-2 align-items-center"
              >
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  According to CSR policy, ECA has no waste production, which
                  means that no garbage is sent to landfills, as it is service
                  providing entity.
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  ECA takes some positive steps, such as turning off the lights
                  in the workplace in the evening, modestly lowering the heating
                  or air conditioning, and unplugging equipment when they are
                  not in use.
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  Using kitchen crockery instead of disposable utensils, and
                  capsules for the coffee machine.
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  Limiting the number of prints, reusing papers as drafts, and
                  correctly sorting waste for recycling.
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  Most of our documents and filings are kept and recorded in
                  electronic and digitalized form
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  ECA employees appropriately use equipment and devices to avoid
                  them deteriorating faster and to fix broken gadgets rather
                  than replacing them with new ones.
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-16">
                  ECA educates clients on the need of making our environment
                  green by adopting certain activities in addition to offering
                  their own services.
                </p>
              </li>
              <li style={{ marginLeft: "5%" }} className="d-flex mb-2">
                <i class="fa fa-chevron-right mr-4" aria-hidden="true"></i>
                <p className="fs-14">
                  ECA tend to focus on using advance and modern technology to
                  work efficiently and accurately.
                </p>
              </li>
            </ul>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          {/* <Footer2 /> */}
          {/* <NewFooter /> */}
          {/* </Suspense> */}
        </section>
      </>
    );
  }
}
