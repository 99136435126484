import React, { Component } from "react";
import "../../css/team.css";

// import t1 from '../assets/img/portfolio/1.jpg'
// import t2 from '../assets/img/portfolio/3.jpg'
// import t3 from '../assets/img/portfolio/2.jpg'
// import Footer2 from "./Footer2";
// import Navigation2 from "../Navigation2";
// import {motion} from 'framer-motion'
// import h1 from 'img/male.png'

import { Helmet } from "react-helmet";

// import metaImg from '../assets/img/portfolio/fullsize/4.jpg'

// import { sloVariants, hVariants } from "../Tools/MotionVariants";
// import { AnimatedDiv, AnimatedH2, AnimatedH3 } from "../Tools/FramerMotionLazy";
// import Navigation from "../HoverNav/Navigation";
// import NewFooter from "../HoverNav/NewFooter";

// const Footer2 = React.lazy(() => import("../Footer2"));

// const imgVariants = {
//     visible:{
//         scale:1.2,
//         transition:{duration:0.4}
//     }
// }

export default class MissionVission extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    readMore: false,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>ECA Mission & Vision</title>
          <meta
            itemprop="name"
            content="Our team is consist of Amazing, talented, Hardworking and Experienced youth"
          />
          <meta
            itemprop="description"
            content="Eagle Wise Chartered Acountants is a buisness consultency agency located in kabul. ECA offers a wide range of services to help grow your business."
          />
          <meta itemprop="image" content={"/logo.jpg"} />
        </Helmet>
        {/* <Navigation2 /> */}
        {/* <Navigation /> */}
        <section
          className="page-section"
          id="team"
          style={{ backgroundColor: "#e6f2f3" }}
        >
          <div className="container">
            <div className="text-center mb-4">
              {/* <AnimatedH2
                variants={hVariants}
                initial="hidden"
                animate="visible"
                className="section-heading text-uppercase"
              >
                Our Mission and Vision
              </AnimatedH2> */}
              {/* <AnimatedH3
                variants={hVariants}
                initial="hidden"
                animate="visible"
                className="section-subheading text-muted "
              >
                Our team consists of Amazing and talented youth
              </AnimatedH3> */}
              <div className="row">
                <div className="col-md-6">
                  <div style={{ marginTop: "22%" }}>
                    <div
                      className="section-title"
                      data-aos="zoom-out"
                      style={{ marginTop: "8%" }}
                    >
                      {/* <h2>About</h2> */}
                      <p>Our Mission</p>
                    </div>

                    <p className="fs-18 mb-3 text-left">
                      ECA is a professional corporation committed to providing
                      the most trusted quality services to clients. In addition,
                      it will go beyond just the numbers and provide
                      non-traditional services. ECA’s goal is to enhance the
                      client's growth by honoring its promises to them, and
                      providing proactive assistance and consultation while
                      continuously developing the staff capacity and serving
                      community by supporting and participating in civil
                      activities
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div style={{ marginTop: "22%" }}>
                    <div
                      className="section-title"
                      data-aos="zoom-out"
                      style={{ marginBottom: "-60px", marginTop: "9%" }}
                    >
                      {/* <h2>About</h2> */}
                      <p
                        className="section-title"
                        style={{ fontWeight: "bold" }}
                      >
                        Our Vision
                      </p>
                    </div>

                    <p className="fs-18 mt-3 text-left">
                      To be reckoned one of the "Best Service Providers" on
                      National and International level.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}>
         
            <NewFooter />
          </Suspense> */}
        </section>
      </>
    );
  }
}
